<script setup lang="ts">
import { Scarcity } from "fungi-types";
import { useI18n } from "vue-i18n";
import { getCardLevel } from "fungi-utils/cardXp";
import { getCardBonus } from "fungi-utils/cardBonus";
import { computed } from "vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    xp?: number;
    season: string;
    scarcity: Scarcity;
  }>(),
  {
    xp: 0,
  }
);

const bonus = computed(() => {
  return getCardBonus({ xp: props.xp, season: props.season, scarcity: props.scarcity });
});

const cardLevel = computed(() => {
  return getCardLevel(props.xp);
});
</script>

<template>
  <Tooltip>
    <div
      class="bg-neutral-950 rounded-md pr-2 pl-1.5 text-neutral-300 capitalize cursor-pointer text-xs h-full items-center flex"
    >
      <span class="">+{{ bonus.total }}</span
      >%
    </div>
    <template #content>
      <div class="w-32 flex flex-col gap-1">
        <div v-if="bonus.season" class="flex flew-row justify-between w-full items-center">
          <div class="text-neutral-850 capitalize">{{ props.season }}</div>
          <div class="bg-green-60 p-1 py-0.5 rounded-md text-neutral-850">
            +{{ bonus.season }} %
          </div>
        </div>
        <div class="flex flew-row justify-between w-full">
          <div class="text-neutral-850 capitalize">{{ t(`scarcities.${scarcity}`) }}</div>
          <div class="bg-green-60 p-1 py-0.5 rounded-md text-neutral-850">
            +{{ bonus.scarcity }} %
          </div>
        </div>
        <div v-if="cardLevel.currentLevel > 0" class="flex flew-row justify-between w-full">
          <div class="text-neutral-850 capitalize">
            {{ t("level") }} {{ cardLevel.currentLevel }}
          </div>
          <div class="bg-green-60 p-1 py-0.5 rounded-md text-neutral-850">
            +{{ cardLevel.bonus }} %
          </div>
        </div>
      </div>
    </template>
  </Tooltip>
</template>
