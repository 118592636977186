<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useDynamicSort from "~/composables/useDynamicSort";
import { useCardDetails } from "~/components/card-details/use-card-details";
import DetailsSection from "~/components/card-details/DetailsSection.vue";
import OwnersHistoryTable from "~/components/card-details/OwnersHistory/OwnersHistoryTable.vue";
import OwnersHistoryCard from "~/components/card-details/OwnersHistory/OwnersHistoryCard.vue";

const { t } = useI18n();

const cardDetails = useCardDetails();
const { ownersHistory: ownersHistoryRef, selectedCard } = storeToRefs(cardDetails);

const ownersHistory = useDynamicSort(ownersHistoryRef);
</script>

<template>
  <DetailsSection
    :name="t('cardDetails.cardPreviousOwners.title')"
    v-if="selectedCard?.card.tokenId != null"
  >
    <OwnersHistoryTable class="hidden lg:table text-xs" />
    <ol class="flex flex-col items-stretch gap-2.5 lg:hidden">
      <li
        v-for="{ owner, price, acquiredAt, transaction } in ownersHistory.sorted.value"
        :key="transaction"
      >
        <OwnersHistoryCard :owner="owner" :date="acquiredAt" :price="price" />
      </li>
      <li v-if="ownersHistory.sorted.value.length === 0">
        <div
          class="bg-black bg-opacity-25 border border-grey-30 border-opacity-20 rounded-lg p-4 flex flex-col items-stretch gap-4"
        >
          {{ t("cardDetails.cardPreviousOwners.noPreviousOwners", "No previous owners") }}
        </div>
      </li>
    </ol>
  </DetailsSection>
</template>
