import {
  format,
  startOfWeek,
  subWeeks,
  endOfWeek,
  isBefore,
  differenceInYears,
  Duration,
  formatDuration,
} from "date-fns";
import { IDateRange } from "~/common/interfaces/ITradingDashboard";
import { enUS, fr } from "date-fns/locale";

export const getlastWeeksDateRange = (
  numberOfWeeks: number,
  since: Date = new Date("2024-02-25 12:00:00")
) => {
  const today = new Date();
  const lastWeeksDateRanges: IDateRange[] = [];

  for (let i = 0; i < numberOfWeeks; i++) {
    const startDate = startOfWeek(subWeeks(today, i), { weekStartsOn: 1 });
    if (isBefore(startDate, since)) break;
    const endDate = endOfWeek(subWeeks(today, i), { weekStartsOn: 1 });
    lastWeeksDateRanges.push({
      from: startDate,
      to: endDate,
    });
  }

  return lastWeeksDateRanges;
};

export const getWeekDateRange = (date: Date = new Date()) => {
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  const endDate = endOfWeek(date, { weekStartsOn: 1 });

  return { from: startDate, to: endDate };
};

export const formatDate = (
  date: string | Date,
  locale: string = "enUS",
  dateFormat: string = "dd MMMM"
) => {
  let res = enUS;
  switch (locale) {
    case "fr":
      res = fr;
      break;
    case "en":
    default:
      res = enUS;
      break;
  }
  return `${format(date, dateFormat, { locale: res })}`;
};

export const getAge = (birthDate: Date | string) => {
  const today = new Date();
  const birthdateObj = new Date(birthDate);
  return differenceInYears(today, birthdateObj);
};

export const formatDateRange = (
  startDate: Date | string,
  endDate: Date | string,
  locale: string = "enUs"
) => {
  let res = enUS;
  switch (locale) {
    case "fr":
      res = fr;
      break;
    case "en":
    default:
      res = enUS;
      break;
  }

  const startDay = format(startDate, "d", { locale: res });
  const startMonth = format(startDate, "MMMM", { locale: res });
  const endDay = format(endDate, "d", { locale: res });
  const endMonth = format(endDate, "MMMM", { locale: res });

  if (startMonth === endMonth) {
    return `${startDay} - ${endDay} ${endMonth}`;
  }

  return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
};

const yearFormatRegex = /(year|ans)s?/;
const monthFormatRegex = /months?|mois/;
const dayFormatRegex = /(day|jour)s?/;
const hourFormatRegex = /(hour|heure)s?/;
const minuteFormatRegex = /minute/;

export const formatDurationShort = (
  duration: Duration,
  locale: "fr" | "en",
  units: (keyof Duration)[] = ["years", "months", "days", "hours", "minutes"]
) => {
  return formatDuration(duration, {
    locale: locale === "fr" ? fr : enUS,
    format: units,
  })
    .replace(yearFormatRegex, locale === "fr" ? "a" : "y")
    .replace(monthFormatRegex, "m")
    .replace(dayFormatRegex, locale === "fr" ? "j" : "d")
    .replace(hourFormatRegex, "h")
    .replace(minuteFormatRegex, "min");
};
