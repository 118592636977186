import { h, markRaw } from "vue";
import { toast } from "vue-sonner";

import SonnerWarningIcon from "~/shadcn/components/ui/sonner/icons/SonnerWarningIcon.vue";
import SonnerInfoIcon from "~/shadcn/components/ui/sonner/icons/SonnerInfoIcon.vue";
import SonnerErrorIcon from "~/shadcn/components/ui/sonner/icons/SonnerErrorIcon.vue";
import SonnerSuccessIcon from "~/shadcn/components/ui/sonner/icons/SonnerSuccessIcon.vue";
import CustomToast from "~/components/CustomToast.vue";

export enum ToastType {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

export type ToastParams = {
  message: string;
  title?: string;
  image?: string;
  type: ToastType;
  duration?: number;
  /**
   * Overrides the duration parameter
   */
  pinned?: boolean;
  callback?: () => unknown;
};

let lastToastId = 1;
const customToastIds = new Map<number, string | number>();

export function showNotification(params: ToastParams) {
  const icon = params.image
    ? undefined
    : {
        success: markRaw(h(SonnerSuccessIcon)),
        warning: markRaw(h(SonnerWarningIcon)),
        error: markRaw(h(SonnerErrorIcon)),
        info: markRaw(h(SonnerInfoIcon)),
        none: undefined,
      }[params.type ?? "none"];

  const customToastId = lastToastId++;
  const sonnerToastId = toast.custom(
    markRaw(h(CustomToast, { ...params, toastId: customToastId }, { icon: icon })),
    {
      position: "top-center",
      ...params,
      duration: params.pinned ? Infinity : params.duration,
      onDismiss: () => {
        customToastIds.delete(customToastId);
      },
    }
  );

  customToastIds.set(customToastId, sonnerToastId);
}

export function closeNotification(toastId: number) {
  const sonnerToastId = customToastIds.get(toastId);

  if (sonnerToastId != null) {
    toast.dismiss(sonnerToastId);

    customToastIds.delete(toastId);
  }
}
