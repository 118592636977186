<script setup lang="ts">
import { inject, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { Skill } from "fungi-types";
import { CurrentlyAvailableCardAuction } from "~/components/card-details/use-card-details";
import HorizontalGauge from "~/components/common/HorizontalGauge.vue";
import OvTicker from "~/components/common/OvTicker.vue";
import BidOrBuyButton from "~/components/card-details/AvailableCards/BidOrBuyButton.vue";

const props = defineProps<{
  card: CurrentlyAvailableCardAuction;
  skill: Skill;
}>();
const { card, skill } = toRefs(props);

const emits = defineEmits<{
  (event: "bid-or-buy", token: CurrentlyAvailableCardAuction): void;
}>();

const skillsColors = inject<Record<Skill, string>>("skills-colors")!;

const { t } = useI18n();
</script>

<template>
  <form
    @submit.prevent.stop
    class="bg-black bg-opacity-25 border border-grey-30 border-opacity-20 rounded-lg p-4 flex flex-col items-stretch gap-4"
  >
    <div class="flex flex-row items-center justify-between">
      <span class="text-grey-40">
        {{ t("cardDetails.availableCards.table.owner", "Owner") }}
      </span>
      <div class="grow flex flex-row items-center align-middle justify-end gap-2 font-semibold">
        <span class="inline-block leading-4 align-middle">{{ card.owner }}</span>
      </div>
    </div>

    <div class="flex flex-row items-center justify-between" v-if="card.wear != null">
      <span class="text-grey-40">
        {{ t("cardDetails.availableCards.table.wear", "Wear") }}
      </span>
      <div class="grow flex flex-row items-center align-middle justify-end gap-2 font-semibold">
        <HorizontalGauge
          :min="0"
          :max="100"
          :value="card.wear"
          :color="skillsColors[skill]"
          show-percentage
        />
      </div>
    </div>

    <div class="flex flex-row items-center justify-between">
      <div class="grow text-grey-40">
        <OvTicker :end-date="card.endedAt" :status="card.status" />
      </div>
      <div class="grow flex flex-row items-center align-middle justify-end gap-2 font-semibold">
        <span class="inline-block leading-4 align-middle">
          {{ card.maticPrice?.toFixed(2) ?? "N/A" }}
        </span>
        <IconFgcWMatic />
      </div>
    </div>

    <BidOrBuyButton :card="card" @click="emits('bid-or-buy', card)" />
  </form>
</template>
