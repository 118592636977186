import { computed, ref, watch } from "vue";
import { CourtType, MarketType, NftSkill, Scarcity, Skill } from "fungi-types";
import { makeApi } from "~/api";
import {
  mapOwnersHistoryDtoToOwnersHistory,
  OwnersHistory,
  OwnersHistoryDto,
} from "~/components/card-details/types/OwnersHistory";
import { BigNumber } from "ethers";
import {
  mapPriceHistoryDtoToPriceHistory,
  PriceHistory,
  PriceHistoryDto,
} from "~/components/card-details/types/PriceHistory";
import {
  mapPlayerScoresDtoToPlayerScores,
  PlayerScores,
  PlayerScoresDto,
} from "~/components/card-details/types/PlayerScores";
import { PriceTimeframe, ScoreTimeframe } from "~/components/card-details/types/StatsTimeframes";
import { Duration, sub } from "date-fns";
import { MarketplaceApi } from "~/api/marketplace";
import {
  IAuctionItem,
  IMarketplaceFilter,
  IMarketplacePrimary,
} from "~/common/interfaces/IMarketplace";
import { defineStore } from "pinia";
import useConvert from "~/composables/useConvert";
import { getCardLevel } from "fungi-utils/cardXp";

import { gameApi } from "~/api/game";

const baseUrl = import.meta.env.VITE_API_URL;
const api = makeApi(baseUrl);
const nftSkillApi = makeApi(`${baseUrl}/services/nftSkill`);
const skillsApi = makeApi(`${baseUrl}/skills`);

const defaultScoresDataPoints: ScoreTimeframe = 10;
const defaultPriceTimeframe: PriceTimeframe = { months: 3 };

const cardSales = ref<CurrentlyAvailableCardAuction[]>([]);

const defaultPriceHistory: PriceHistory = {
  params: {},
  data: [],
  stats: {
    dataPoints: 0,
    average: BigNumber.from(0),
    min: BigNumber.from(0),
    max: BigNumber.from(0),
  },
};

const defaultPlayerScores: PlayerScores = {
  data: [],
  stats: {
    dataPoints: 0,
    participationRate: 0,
    averages: {
      [Skill.MENTAL]: 0,
      [Skill.POWER]: 0,
      [Skill.RETURN]: 0,
      [Skill.SERVICE]: 0,
    },
  },
};

const primaryMarketplaceApi = new MarketplaceApi(MarketType.PRIMARY);
const secondaryMarketplaceApi = new MarketplaceApi(MarketType.SECONDARY);

export type CurrentlyAvailableCardAuction = IAuctionItem & {
  placeholder?: boolean;
  owner: IAuctionItem["card"]["owner"];
  level: NftSkill["skillLevel"];
  wear?: number;
  maticPrice?: number;
};

const { weiToMatic } = useConvert;

export const useCardDetails = defineStore("card-details", () => {
  // const card = ref<NftSkill | null>(null);
  const placeholderCard = ref<{ card: NftSkill } | null>(null);
  const tennisPlayerId = ref<number | null>(null);
  const competitorId = ref<string | null>(null);
  const selectedSkill = ref<Skill | null>(null);
  const selectedScarcity = ref<Scarcity | null>(null);

  const scoresDataPoints = ref<ScoreTimeframe>(defaultScoresDataPoints);
  const priceTimeframe = ref<PriceTimeframe>(defaultPriceTimeframe as PriceTimeframe);

  const loadingOwnersHistory = ref(false);
  const loadingPriceHistory = ref(false);
  const loadingScores = ref(false);
  const loadingSales = ref(false);

  const showModal = ref(false);

  const ownersHistory = ref<OwnersHistory>([]);
  const getOwnersHistory = async (tokenId: NftSkill["tokenId"]) => {
    const res = await api.get<OwnersHistoryDto>(`/skills/${tokenId}/owners`);
    return mapOwnersHistoryDtoToOwnersHistory(res.data);
  };

  const priceHistory = ref(defaultPriceHistory);
  const getCardPriceHistory = async () => {
    loadingPriceHistory.value = true;
    const time_start =
      priceTimeframe.value !== undefined
        ? sub(new Date(), priceTimeframe.value as Duration).toISOString()
        : undefined;

    const params = {
      tennisPlayerId: tennisPlayerId.value,
      scarcity: selectedScarcity.value,
      skill: selectedSkill.value,
      season: selectedCard.value?.card.season,
      time_end: new Date().toISOString(),
      time_start,
    };

    const res = await api.get<PriceHistoryDto>(`/skills/price-history`, {
      params,
    });

    loadingPriceHistory.value = false;
    return mapPriceHistoryDtoToPriceHistory(res.data);
  };

  const scores = ref(defaultPlayerScores);
  const getScores = async () => {
    const res = await fetchScores({
      competitor_id: competitorId.value,
      tennisPlayerId: tennisPlayerId.value,
      data_points: scoresDataPoints.value,
    });
    scores.value = res;
  };

  const getSales = async (): Promise<[IMarketplacePrimary, IMarketplacePrimary] | null> => {
    if (
      !selectedScarcity.value ||
      !selectedSkill.value ||
      !tennisPlayerId.value ||
      !selectedCard.value
    )
      return null;

    const filters: IMarketplaceFilter = {
      player_name: "",
      scarcity: [selectedScarcity.value],
      skill: [selectedSkill.value],
      tennisPlayerId: tennisPlayerId.value,
      season: [selectedCard.value.card.season.toString()],
    };
    const sales = await Promise.all([
      primaryMarketplaceApi.getCards(filters),
      secondaryMarketplaceApi.getCards(filters),
    ]);

    const onSale = sales
      .map((result) => result.data)
      .flat()
      .map<CurrentlyAvailableCardAuction>((sale) => ({
        ...sale,
        owner:
          sale.marketType === "primary"
            ? "Fungiball"
            : ((sale.card.ownedBy?.user as { username: string } | undefined)?.username ?? "N/A"),
        level: getCardLevel((sale.card as NftSkill).xp).currentLevel,
        maticPrice: weiToMatic(BigNumber.from(sale.lastBidAmount ?? sale.startedAmount!)),
      }));
    cardSales.value = onSale;
    return sales;
  };

  const fetchScores = async (params: {
    competitor_id: string;
    skills: Skill[];
    data_points?: number;
    stats_only?: boolean;
  }) => {
    const res = await gameApi.scores.competitors.get.query(params);
    if (!res) return defaultPlayerScores;
    return mapPlayerScoresDtoToPlayerScores(res);
  };

  const l10Scores = ref(defaultPlayerScores.stats.averages);
  const getL10Scores = async () => {
    const params = {
      competitor_id: competitorId.value,
      data_points: scoresDataPoints.value,
    };

    const scores = await fetchScores(params);
    if (!scores) return defaultPlayerScores.stats.averages;
    return {
      [Skill.MENTAL]: Math.round(scores.stats.averages[Skill.MENTAL]),
      [Skill.POWER]: Math.round(scores.stats.averages[Skill.POWER]),
      [Skill.RETURN]: Math.round(scores.stats.averages[Skill.RETURN]),
      [Skill.SERVICE]: Math.round(scores.stats.averages[Skill.SERVICE]),
    } as Record<Skill, number>;
  };

  type cardType = { card: NftSkill } | null;
  type cardsSlots = {
    [key in Skill]: cardType;
  };

  const defaultCards: cardsSlots = {
    return: null,
    service: null,
    power: null,
    mental: null,
  };
  const cards = ref<cardsSlots>(defaultCards);
  const selectedCard = ref<cardType>(null);

  const clearCard = () => {
    tennisPlayerId.value = null;
    cardSales.value = [];
    selectedSkill.value = null;
    cards.value.mental = null;
    cards.value.power = null;
    cards.value.return = null;
    cards.value.service = null;
    ownersHistory.value = [];
    priceHistory.value = defaultPriceHistory;
    scores.value = defaultPlayerScores;
    l10Scores.value = defaultPlayerScores.stats.averages;
  };

  const loading = ref(true);

  const initCardDetails = async (tokenId: NftSkill["tokenId"], tempValue?: cardType) => {
    clearCard();
    if (tempValue) {
      tennisPlayerId.value = tempValue.card.tennisPlayerId;
      competitorId.value = tempValue.card.competitorId;
      selectedSkill.value = tempValue.card.skill;
      selectedScarcity.value = tempValue.card.scarcity;
      cards.value[selectedSkill.value] = tempValue;
    } else {
      // const res = await nftSkillApi.get<nftSkillApiResponseType>(`/${tokenId}`);
      // tennisPlayerId.value = res.data.tennisPlayer.id;
    }

    await getScores();
    l10Scores.value = await getL10Scores();
  };

  const loadCardStats = async () => {
    if (!selectedCard.value) return;

    priceHistory.value = await getCardPriceHistory();

    ownersHistory.value = await getOwnersHistory(selectedCard.value?.card.tokenId ?? 0);
    await getSales();
  };

  const changeSkill = async (skill: Skill) => {
    selectedSkill.value = skill;
    await loadCardStats();
  };

  const changePriceTimeframe = async (timeframe: PriceTimeframe) => {
    priceTimeframe.value = timeframe;
    priceHistory.value = await getCardPriceHistory();
  };

  const changeScoresDataPoints = async (dataPoints: ScoreTimeframe) => {
    scoresDataPoints.value = dataPoints;
    await getScores();
  };

  const loadCard = async (tokenId: number, tempValue: cardType) => {
    clearCard();
    loading.value = true;
    selectedCard.value = tempValue;
    await initCardDetails(tokenId, tempValue);
    await loadCardStats();

    loading.value = false;
  };

  return {
    selectedCard,
    placeholderCard,
    selectedSkill,
    ownersHistory,
    priceHistory,
    scores,
    l10Scores,
    cardSales,
    scoresDataPoints,
    priceTimeframe,
    loadingOwnersHistory,
    loadingPriceHistory,
    loadingScores,
    loadingSales,
    showModal,
    changeSkill,
    loadCard,
    changePriceTimeframe,
    changeScoresDataPoints,
  };
});
