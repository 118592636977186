<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useStoreDeck } from "~/stores/storeDeck";
import { NotifType, useStoreApp } from "~/stores/storeApp";
import { computed, ref } from "vue";
import { BigNumber } from "ethers";
import useConvert from "~/composables/useConvert";
import { MarketplaceApi } from "~/api/marketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { SeaportService } from "~/services/seaportService";
import { displayError } from "~/utils/errors";
import { useI18n } from "vue-i18n";
import { CardType, MarketType, NftEquipment, NftSkill as TNftSkill } from "fungi-types";
import Button from "../formsV2/Button.vue";
import NftSkill from "~/components/marketplace/NftSkill.vue";
import { userToken } from "~/utils/localStorage";

const { maticToWei } = useConvert;
const storeDeck = useStoreDeck();
const storeUser = useStoreUserV2();
const bidAmount = ref(maticToWei(0));
const minAmount = ref(maticToWei(1));
const decimals = ref(3);
const { selected } = storeToRefs(storeDeck);
const marketplaceApi = new MarketplaceApi(MarketType.SECONDARY);
const loading = ref(false);
const { t } = useI18n();

const emits = defineEmits<{
  (event: "closed"): void;
}>();

const storeApp = useStoreApp();

const amountTooLow = computed(() => bidAmount.value.lt(minAmount.value));

function updateBidAmount(e: BigNumber) {
  bidAmount.value = e;
}

async function list() {
  try {
    loading.value = true;
    const seaportService = new SeaportService(storeUser.wallet.signer);
    const params = await marketplaceApi.listingParams(
      selected.value.tokenId,
      bidAmount.value.toString(),
      userToken.get(),
      selected.value.card_type
    );
    const orderWithCounter = await seaportService.getOrderSignature(
      params,
      storeUser.walletAddress
    );
    const { success, auction } = await marketplaceApi.list(
      orderWithCounter,
      selected.value.tokenId,
      userToken.get(),
      selected.value.card_type
    );

    if (success && auction) {
      storeApp.displayNotification(NotifType.LIST_SUCCESS, { ...auction, card: selected.value });
      storeDeck.updateItem(selected.value.tokenId, {
        card_type: selected.value.card_type,
        auction: auction,
      });
      emits("closed");
    } else {
      storeApp.displayNotification(
        NotifType.LIST_FAILED,
        selected.value.auction ??
          (selected.value.card_type === CardType.NftEquipment
            ? {
                firstName: (selected.value as NftEquipment).title,
                lastName: "",
                skill: "",
                scarcity: "",
              }
            : {
                firstName: (selected.value as TNftSkill).firstName,
                lastName: (selected.value as TNftSkill).lastName,
                skill: (selected.value as TNftSkill).skill,
                scarcity: (selected.value as TNftSkill).scarcity,
              })
      );
      displayError(t("modals.list.messages.error-unlist"));
    }
    loading.value = false;
  } catch (e: any) {
    if (e.response?.data?.code === "E_NFT_INUSE") {
      displayError(t("modals.list.messages.error-in-use"));
    } else {
      displayError(t("modals.list.messages.error"));
    }
    loading.value = false;
  }
}
</script>

<template>
  <div class="grid grid-cols-3 text-white md:max-w-[60vw]">
    <div class="hidden md:flex gap-f4 mx-auto bg-dark-blury gradient-border bg-left">
      <div class="flex flex-col">
        <NftSkill :nft="selected" :background="false">
          <template #footer-data>
            <CardDataFooterSkeleton>
              <template #header>
                <CardDataFooterScore
                  :l10="selected.score"
                  :skill="selected.skill"
                  :scarcity="selected.scarcity"
                  :token-id="selected.tokenId"
                  :l5="selected.l5"
                  :court="selected.courtType"
                />
              </template>
            </CardDataFooterSkeleton>
          </template>
        </NftSkill>
        <span class="text-center font-display uppercase title-xs-baseline"
          >{{ selected.firstName }} {{ selected.lastName }}</span
        >
      </div>
    </div>
    <div
      class="flex flex-col justify-around gap-2 md:gap-f3 col-span-3 md:col-span-2 bg-dark-blury before:z-[-1] shadow-xl py-8 px-f4 rounded-2xl md:rounded-l-none md:before:rounded-r-2xl md:gradient-border"
    >
      <div class="flex items-center justify-between">
        <h1 class="title-sm d-s:title-md font-display uppercase">{{ $t("modals.list.title") }}</h1>
        <button>
          <icon-mingcute:close-fill @click="emits('closed')" class="w-f4 h-f4" />
        </button>
      </div>
      <div class="flex flex-col gap-f1 px-f3 md:hidden items-center justify-center">
        <div class="flex flex-col w-4/6">
          <NftSkill :nft="selected" :background="false" />
          <span class="text-center font-display uppercase title-xs-baseline"
            >{{ selected.firstName }} {{ selected.lastName }}</span
          >
        </div>
      </div>
      <div class="flex flex-col gap-f3">
        <span class="font-bold uppercase">{{ $t("modals.list.total") }}</span>
        <ConverterInput
          @wei-amount="updateBidAmount"
          :hideConversion="false"
          :decimals="decimals"
          :canSwitchMode="false"
        >
          <template #msg v-if="amountTooLow">
            <span class="text-error">{{ $t("modals.bid.toolow") }}</span>
          </template>
        </ConverterInput>
      </div>
      <Button :disabled="amountTooLow" type="primary-blue" class="uppercase" @click="list">
        <icon-fgc-loading v-if="loading" />
        <span v-else>{{ $t("modals.list.confirm") }}</span>
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-left {
  @apply py-f3 px-f1 rounded-l-2xl before:rounded-l-2xl before:z-[-1] shadow-xl;
}
</style>
