<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { formatDate } from "~/utils/dates";

const props = defineProps<{
  owner: string;
  price: number;
  date: Date;
}>();

const { t, locale } = useI18n();
</script>

<template>
  <div
    class="bg-black bg-opacity-25 border border-grey-30 border-opacity-20 rounded-lg p-4 flex flex-col items-stretch gap-4"
  >
    <div class="flex flex-row items-center justify-between">
      <span class="text-grey-40">
        {{ t("cardDetails.cardPreviousOwners.table.name", "Name") }}
      </span>
      <span class="grow flex flex-row items-center justify-end gap-2 font-semibold">
        {{ props.owner }}
      </span>
    </div>

    <div class="flex flex-row items-center justify-between">
      <span class="text-grey-40">
        {{ t("cardDetails.cardPreviousOwners.table.boughtFor", "Bought for") }}
      </span>
      <div class="grow flex flex-row items-center align-middle justify-end gap-2 font-semibold">
        <span class="inline-block leading-4 align-middle">{{ props.price.toFixed(2) }}</span>
        <IconFgcWMatic />
      </div>
    </div>

    <div class="flex flex-row items-center justify-between">
      <span class="text-grey-40">
        {{ t("cardDetails.cardPreviousOwners.table.date", "Date") }}
      </span>
      <span class="grow flex flex-row items-center justify-end gap-2 font-semibold">
        {{ formatDate(date, locale, locale.startsWith("fr") ? "dd/MM/yyyy" : "MM/dd/yyyy") }}
      </span>
    </div>
  </div>
</template>
