import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "svc-notification/src/router";
import { userToken } from "~/utils/localStorage";

const baseUrl = import.meta.env.VITE_NOTIFICATION_SERVICE_URL;

export const notificationApi = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: baseUrl,
      async headers() {
        return {
          authorization: "Bearer " + userToken.get(),
        };
      },
    }),
  ],
});
