<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { LogOut, Settings, UserPlus, X, BookText } from "lucide-vue-next";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Separator } from "~/shadcn/components/ui/separator";
import { SheetContent, SheetTitle } from "~/shadcn/components/ui/sheet";
import { DrawerContent, DrawerTitle } from "~/shadcn/components/ui/drawer";
import ProfileDrawerSection from "~/components/profile/ProfileDrawerSection.vue";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { default as XIcon } from "/icon-X.svg?url";
import Instagram from "/icon-instagram.svg?url";
import Discord from "/icon-discord.svg?url";
import Button from "~/components/formsV2/Button.vue";
import ProfileDrawerFooterButton from "~/components/profile/ProfileDrawerFooterButton.vue";

import ProfileDrawerWallet from "~/components/profile/ProfileDrawerWallet.vue";
import ProfileDrawerToken from "../ProfileDrawerToken.vue";

const { t, locale } = useI18n();
const router = useRouter();

const contentRef = ref(null);

const breakpoints = useBreakpoints(breakpointsTailwind);

const breakpointIsLgOrAbove = breakpoints.greaterOrEqual("lg");

const userStore = useStoreUserV2();

const handleReferralNavigation = () => {
  setTimeout(() => {
    userStore.closeProfileDrawer();
  }, 0);

  if (router.currentRoute.value.name !== "Referral") {
    router.push({ name: "Referral" });
  }
};

const social = [
  { alt: "X", url: "https://x.com/Fungiball_off", icon: XIcon },
  { alt: "Instagram", url: "https://www.instagram.com/fungiball.io", icon: Instagram },
  { alt: "Discord", url: "https://discord.gg/YJJw7RP33K", icon: Discord },
];

const handleLogout = () => {
  userStore.logout();
  userStore.closeProfileDrawer();
  router.push({ name: "Home" });
};

const openGuide = () => {
  if (locale.value === "fr") {
    window.open("https://guide.fungiball.io", "_blank");
  } else {
    window.open("https://guide.fungiball.io/en", "_blank");
  }
  userStore.closeProfileDrawer();
};
</script>

<template>
  <component
    :is="breakpointIsLgOrAbove ? SheetContent : DrawerContent"
    class="border-none outline-none p-0 bg-slate-950 text-white h-screen flex flex-col"
    hide-default-close-button
    ref="contentRef"
  >
    <component
      :is="breakpointIsLgOrAbove ? SheetTitle : DrawerTitle"
      class="p-6 flex items-center justify-between"
    >
      <span class="">{{ t("drawers.profile.defaultView.myProfile", "My profile") }}</span>
      <X
        class="w-4 cursor-pointer hidden lg:block"
        aria-label="Close"
        @click.stop="userStore.closeProfileDrawer"
      />
    </component>

    <Separator class="bg-slate-600" />

    <div
      class="grow p-6 flex flex-col justify-start items-stretch gap-6 overflow-y-auto pb-12 lg:pb-6"
    >
      <ProfileDrawerSection>
        <ProfileDrawerUserInfo />
      </ProfileDrawerSection>

      <ProfileDrawerSection :title="t('drawers.profile.defaultView.wallet.wallet')">
        <ProfileDrawerWallet />
      </ProfileDrawerSection>

      <ProfileDrawerSection :title="t('drawers.profile.defaultView.token.token')">
        <ProfileDrawerToken />
      </ProfileDrawerSection>

      <ProfileDrawerSection
        :title="t('drawers.profile.defaultView.account.account', 'Account')"
        class="flex flex-col items-stretch gap-2"
      >
        <ProfileDrawerButton nav @click="handleReferralNavigation">
          <template #icon>
            <UserPlus class="w-4" />
          </template>
          {{ t("drawers.profile.defaultView.account.inviteAFriend", "Invite a friend") }}
        </ProfileDrawerButton>

        <ProfileDrawerButton nav @click="userStore.openProfileDrawer('settings')">
          <template #icon>
            <Settings class="w-4" />
          </template>
          {{ t("drawers.profile.defaultView.account.settings", "Settings") }}
        </ProfileDrawerButton>

        <ProfileDrawerButton nav @click="openGuide">
          <template #icon>
            <BookText class="w-4" />
          </template>
          Guide
        </ProfileDrawerButton>
      </ProfileDrawerSection>

      <ProfileDrawerSection :title="t('drawers.profile.defaultView.account.followUs', 'Follow us')">
        <ul class="flex flex-row items-center justify-center gap-2 w-full">
          <li v-for="item in social" :key="item.alt" class="grow flex justify-center items-center">
            <a
              :href="item.url"
              target="_blank"
              class="w-full rounded-lg bg-slate-900 hover:bg-slate-800 flex justify-center items-center p-2 max-h-8 lg:max-h-10 xl:max-h-12"
            >
              <img :src="item.icon" :alt="item.alt" class="text-white w-4" />
            </a>
          </li>
        </ul>
      </ProfileDrawerSection>

      <div class="grow flex flex-col justify-end items-stretch">
        <Button
          @click="handleLogout"
          class="flex flex-row items-center justify-center gap-2 rounded-lg bg-transparent"
          type="primary-outline"
        >
          <LogOut class="w-4" />
          <span>
            {{ t("drawers.profile.defaultView.account.logOut", "Log out") }}
          </span>
        </Button>
      </div>
    </div>

    <ProfileDrawerFooterButton @click="userStore.closeProfileDrawer()">
      {{ t("drawers.close", "Close") }}
    </ProfileDrawerFooterButton>
  </component>
</template>
