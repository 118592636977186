<script setup lang="ts"></script>

<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 0C16.023 0 20.5 4.477 20.5 10C20.5021 12.6255 19.4715 15.1464 17.6309 17.0186C15.7902 18.8908 13.2872 19.9641 10.662 20.0066C8.03689 20.0491 5.50034 19.0576 3.6 17.246C1.69966 15.4344 0.587989 12.9482 0.505 10.324L0.5 10L0.504 9.72C0.652 4.327 5.07 0 10.5 0ZM10.51 13L10.383 13.007C10.14 13.0359 9.91594 13.153 9.75341 13.336C9.59088 13.519 9.50111 13.7552 9.50111 14C9.50111 14.2448 9.59088 14.481 9.75341 14.664C9.91594 14.847 10.14 14.9641 10.383 14.993L10.5 15L10.627 14.993C10.87 14.9641 11.0941 14.847 11.2566 14.664C11.4191 14.481 11.5089 14.2448 11.5089 14C11.5089 13.7552 11.4191 13.519 11.2566 13.336C11.0941 13.153 10.87 13.0359 10.627 13.007L10.51 13ZM10.5 5C10.2551 5.00003 10.0187 5.08996 9.83563 5.25272C9.65259 5.41547 9.53566 5.63975 9.507 5.883L9.5 6V10L9.507 10.117C9.53591 10.36 9.65296 10.5841 9.83597 10.7466C10.019 10.9091 10.2552 10.9989 10.5 10.9989C10.7448 10.9989 10.981 10.9091 11.164 10.7466C11.347 10.5841 11.4641 10.36 11.493 10.117L11.5 10V6L11.493 5.883C11.4643 5.63975 11.3474 5.41547 11.1644 5.25272C10.9813 5.08996 10.7449 5.00003 10.5 5Z"
      fill="#F4C734"
    />
  </svg>
</template>
