<script setup lang="ts">
const emit = defineEmits(["click"]);
</script>

<template>
  <button
    class="lg:hidden border-t border-white py-3 flex justify-center items-center gap-2"
    @click.stop="emit('click')"
  >
    <div>
      <slot name="icon" />
    </div>
    <span>
      <slot />
    </span>
  </button>
</template>
