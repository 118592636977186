import { IMetaData, IPagination } from "~/common/interfaces/IMarketplace";
import { IUserDeck, IUserEquipment } from "~/common/interfaces/IUser";
import { ITransaction } from "~/common/interfaces/ITransaction";
import { NftType } from "fungi-types";
import { makeApi } from "~/api/index";
import { IMyDeckFilter, IMyEquipmentFilter } from "~/common/interfaces/IMyDeck";

const baseURL = import.meta.env.VITE_API_URL;
const api = makeApi(`${baseURL}/user`);

export const userApi = {
  getUserDeck: async (
    identifier: string,
    filters: IMyDeckFilter,
    type: NftType = NftType.SKILL,
    pagination?: IPagination
  ): Promise<IUserDeck> => {
    const { data } = await api.get<IUserDeck>(
      `${identifier}/deck${type === NftType.EQUIPMENT ? "-equipment" : ""}`,
      {
        params: {
          f2p: filters?.f2p,
          deckId: filters?.deckId,
          isListed: filters.isListed,
          player_name: filters.player_name,
          scarcity: filters.scarcity?.join(","),
          skill: filters.skill?.join(","),
          skill_score: filters.skill_score?.join(","),
          skill_level: filters.skill_level?.join(","),
          court_type: filters.court_type?.join(","),
          gender: filters.gender?.join(","),
          age: filters.age?.join(","),
          country_iso: filters.country_iso
            ?.map((country) => country.alpha3.toUpperCase())
            .join(","),
          season: filters.season?.join(","),
          edition: filters.edition?.join(","),
          xpLevel: filters.xpLevel?.join(","),
          sort: filters.sort,
          page: pagination?.page,
          per_page: pagination?.per_page,
        },
      }
    );
    return data as IUserDeck;
  },
  getActivity: async (
    pagination?: IPagination
  ): Promise<{ meta: IMetaData; data: ITransaction[] }> => {
    const { data } = await api.get(`activity`, {
      params: {
        page: pagination?.page,
        per_page: pagination?.per_page,
      },
    });
    return data;
  },

  getUserEquipment: async (
    identifier: string,
    filters: IMyEquipmentFilter,
    pagination?: IPagination
  ): Promise<IUserEquipment> => {
    const { data } = await api.get<IUserEquipment>(`${identifier}/list-equipment`, {
      params: {
        equipment_name: filters.equipment_name,
        brand: filters.brand?.join(","),
        equipment_level: filters.equipment_level?.join(","),
        sort: filters.sort,
        page: pagination?.page,
        per_page: pagination?.per_page,
      },
    });
    return data;
  },

  craftEquipment: async (
    identifier: string,
    equipmentName: string
  ): Promise<{
    title: string;
    brand: string;
    gender: string;
    edition: string;
    scarcity_ratio: null;
    bonus: number;
    owner: string;
    image: string;
    model_url: string;
    tokenId: number;
    level: number;
    is_active: true;
    card_type: "nft_equipment";
  }> => {
    const { data } = await api.put(`${identifier}/craft-equipment`, {
      modelName: equipmentName,
    });
    return data;
  },
};
