<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStoreUserV2 } from "~/stores/storeUserV2";

const { t } = useI18n();

const userStore = useStoreUserV2();
</script>

<template>
  <div class="grid grid-cols-1 grid-rows-1 gap-2" ref="overlayParentRef">
    <div
      class="lg:col-span-3 px-5 py-3 flex flex-col items-center justify-center gap-2 lg:gap-1 bg-slate-900 rounded-lg"
    >
      <span class="text-xs text-slate-400">
        {{ t("drawers.profile.defaultView.token.balance", "Balance") }}
      </span>
      <span class="font-semibold text-base text-white">
        {{ userStore.tokenBalance }}&nbsp;
        {{ t("_shared.token", userStore.tokenBalance) }}
      </span>
    </div>
  </div>
</template>
