<script setup lang="ts">
  import { ref } from "vue";
  import Trans from "~/modules/translations";
  import { useStoreUserV2 } from "~/stores/storeUserV2";
  import { useI18n } from "vue-i18n";

  const userStore = useStoreUserV2();
  const { t } = useI18n();
  const activeLink = ref("market");
  const activeLinkHandler = (name: string) => {
    activeLink.value = name;
  };
  const buttonActive = (name: string) => {
    userStore.openProfileDrawer('default')
    const previousActiveLink = activeLink.value;
    activeLink.value = name;
    setTimeout(() => {
      activeLink.value = previousActiveLink;
    }, 300);
  }
</script>

<template>
  <nav class="nav-container">
    <div class="flex items-center justify-evenly text-xs font-medium text-grey-70">
      <div @click="() => activeLinkHandler('market')">
        <router-link :to="Trans.i18nRoute({ name: 'NewCards' })" class="nav-item"
          :class="activeLink === 'market' ? 'text-white' : ''">
          <div class="icon-container">
            <icon-fg-market />
          </div>
          <span>{{ t("navigation.Market") }}</span>
        </router-link>
      </div>

      <div @click="() => activeLinkHandler('data')">
        <router-link :to="Trans.i18nRoute({ name: 'Dashboards' })" class="nav-item"
          :class="activeLink === 'data' ? 'text-white' : ''">
          <div class="icon-container">
            <icon-fg-data v-if="activeLink !== 'data'"/>
            <icon-fg-data-active v-else class="stroke-white" />
          </div>
          <span>{{ t("nav.data") }}</span>
        </router-link>
      </div>

      <div @click="() => activeLinkHandler('play')">
        <router-link :to="Trans.i18nRoute({ name: 'SelectCompetitionCategory' })" class="nav-item"
          :class="activeLink === 'play' ? 'text-white' : ''">
          <div class="icon-container">
            <icon-fg-tennis-racket />
          </div>
          <span>{{ t("nav.play") }}</span>
        </router-link>
      </div>

      <div @click="() => activeLinkHandler('myCards')">
        <router-link :to="Trans.i18nRoute({ name: 'MyCards' })" class="nav-item"
          :class="activeLink === 'myCards' ? 'text-white' : ''">
          <div class="icon-container">
            <icon-fg-cards />
          </div>
          <span>{{ t("navigation.MyDeck") }}</span>
        </router-link>
      </div>

      <button tabindex="-1" @click="() => buttonActive('profile')" class="nav-item"
        :class="activeLink === 'profile' ? 'text-white' : ''">
        <div class="w-5 h-5 rounded-full mt-1 mb-0.5">
          <img src="/images/avatar.png" alt="avatar" class="object-contain" />
        </div>

        <span class="truncate">{{ t("nav.profile") }}</span>
    </button>
  </div>
</nav></template>

<style scoped lang="postcss">
.nav-container {
  @apply flex flex-col justify-center lg:hidden fixed  px-f1 bottom-0 left-0 right-0 bg-primary-110 h-[60px] border-t-2 border-white/20;
}

.nav-item {
  @apply flex flex-col gap-0 items-center justify-center;
}

.icon-container {
  @apply w-6 aspect-square flex items-center justify-center;
}

span {
  @apply truncate max-w-full;
}
</style>
