<script setup lang="ts">
import { BigNumber } from "ethers";
import { AuctionStatus, MarketType, Currency } from "fungi-types";
import { computed } from "vue";
import OvTicker from "~/components/common/OvTicker.vue";
import useConvert from "~/composables/useConvert";
import usePOLPrice from "~/stores/POLprice";

const { weiToMatic, formatUsd } = useConvert;
const polPrice = usePOLPrice();

import { useStoreUserV2 } from "~/stores/storeUserV2";
import { roundToDecimals } from "~/utils/math";

const storeUser = useStoreUserV2();

const props = withDefaults(
  defineProps<{
    weiAmount: BigNumber;
    marketType: MarketType;
    cuid: string;
    endedAt: string;
    status: AuctionStatus;
    lastBidder: string;
  }>(),
  {}
);

const emits = defineEmits<{ (event: "time-out"): void }>();
const handleTimeoutEvent = () => emits("time-out");

const highlightName = computed(() => {
  return storeUser.username === props.lastBidder;
});

const amountInPol = computed(() => {
  return formatUsd(weiToMatic(props.weiAmount), false);
});

const amountInCurrency = computed(() => {
  return (currency: Currency) => {
    const preciseFiatAmount = polPrice.weiToCurrency(props.weiAmount, currency);

    return roundToDecimals(preciseFiatAmount, 2);
  };
});

const amountInPreferredCurrency = computed(() => {
  if (storeUser.preferredCurrency === Currency.WMATIC || !storeUser.preferredCurrency) {
    return amountInPol.value;
  } else {
    return amountInCurrency.value((storeUser.preferredCurrency as Currency) ?? "wmtc");
  }
});
</script>

<template>
  <div class="w-full flex flex-col gap-0.5 cursor-default">
    <div class="w-full flex justify-between">
      <div
        v-if="
          marketType !== MarketType.SECONDARY &&
          [Currency.EUR, Currency.USD].includes(storeUser.preferredCurrency)
        "
        class="flex gap-2"
      >
        <div class="text-lg gap-1 flex font-semibold">
          <span>{{ amountInPreferredCurrency }}</span>
          <span class="font-semibold">
            <template v-if="storeUser.preferredCurrency === Currency.EUR">€</template>
            <template v-else>$</template>
          </span>
        </div>
        <div class="flex items-center text-slate-300 text-sm gap-1 mt-[1px]">
          <span>{{ amountInPol }}</span>
          <icon-fgc-w-matic class="w-3.5 h-3.5 grayscale" />
        </div>
      </div>
      <div v-else class="text-lg gap-1 flex items-center font-semibold">
        <span>{{ amountInPol }}</span>
        <icon-fgc-w-matic class="w-f3 h-f3" />
      </div>
    </div>
    <div class="w-full flex items-center gap-2 justify-between text-slate-300 text-xs">
      <OvTicker
        @time-out="handleTimeoutEvent"
        :cuid="cuid"
        :end-date="endedAt"
        :status="status"
        class=""
      />
      <div class="grow flex flex-1 justify-end items-center overflow-hidden gap-1">
        <p class="truncate" :class="highlightName ? 'highlightName' : ''">
          {{ lastBidder }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.highlightName {
  @apply font-medium text-yellow-40;
}
</style>
