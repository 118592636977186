<script setup lang="ts"></script>

<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 1.34014C17.0083 2.21099 18.2629 3.46066 19.1398 4.96544C20.0167 6.47021 20.4854 8.17791 20.4994 9.91948C20.5135 11.661 20.0725 13.3761 19.22 14.8948C18.3676 16.4136 17.1332 17.6834 15.6392 18.5785C14.1452 19.4736 12.4434 19.963 10.7021 19.9982C8.96083 20.0334 7.24055 19.6133 5.71156 18.7794C4.18256 17.9455 2.89787 16.7267 1.98467 15.2437C1.07146 13.7606 0.561409 12.0648 0.505 10.3241L0.5 10.0001L0.505 9.67614C0.561003 7.94913 1.06355 6.2661 1.96364 4.79113C2.86373 3.31616 4.13065 2.09959 5.64089 1.26002C7.15113 0.420449 8.85315 -0.0134666 10.581 0.000574386C12.3089 0.0146154 14.0036 0.476134 15.5 1.34014ZM9.011 7.14014C8.80148 7.01541 8.55361 6.97154 8.31401 7.01677C8.07441 7.06199 7.85959 7.1932 7.70995 7.38572C7.56031 7.57823 7.48617 7.81879 7.50146 8.06214C7.51676 8.30549 7.62044 8.53487 7.793 8.70714L9.085 10.0001L7.793 11.2931L7.71 11.3871C7.55459 11.5881 7.48151 11.8407 7.5056 12.0937C7.5297 12.3466 7.64916 12.5809 7.83972 12.7489C8.03029 12.9169 8.27767 13.0061 8.53162 12.9984C8.78557 12.9906 9.02704 12.8865 9.207 12.7071L10.5 11.4151L11.793 12.7071L11.887 12.7901C12.088 12.9455 12.3406 13.0186 12.5935 12.9945C12.8465 12.9704 13.0807 12.851 13.2488 12.6604C13.4168 12.4698 13.506 12.2225 13.4982 11.9685C13.4905 11.7146 13.3863 11.4731 13.207 11.2931L11.915 10.0001L13.207 8.70714L13.29 8.61314C13.4454 8.41214 13.5185 8.15953 13.4944 7.90661C13.4703 7.65369 13.3508 7.41942 13.1603 7.25139C12.9697 7.08335 12.7223 6.99415 12.4684 7.00191C12.2144 7.00966 11.973 7.11379 11.793 7.29314L10.5 8.58514L9.207 7.29314L9.113 7.21014L9.011 7.14014Z"
      fill="#F54629"
    />
  </svg>
</template>
