<script setup lang="ts">
import { useI18n } from "vue-i18n";

const props = defineProps<{
  score: number;
  size: "sm" | "md";
}>();

const { t } = useI18n();

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>

<template>
  <Tooltip theme="light" :text="t('card.l10.tooltipLegend')">
    <div
      @click.stop="handleClick"
      :class="{
        ' rounded-lg': true,
        'bg-[#CCD0D6]': score === null,
        'bg-[#40AA48]': score !== null && score >= 71,
        'bg-[#32aecd]': score !== null && score >= 51 && score <= 70,
        'bg-[#F2BD0D]': score !== null && score >= 31 && score <= 50,
        'bg-[#E38112]': score !== null && score >= 11 && score <= 30,
        'bg-[#BD270E]': score !== null && score < 11,
      }"
      class="rounded-md px-2 text-sm lg:text-sm score-container grid place-content-center text-black"
    >
      <p class="font-sans font-medium" :class="[size === 'sm' ? 'text-xs py-0.5' : '']">
        {{ score }}
      </p>
    </div>
  </Tooltip>
</template>
