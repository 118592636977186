import { Scarcity } from "fungi-types";
import { getCardLevel } from "./cardXp";

export const getCardBonus = (input: {
  xp: number;
  scarcity: Scarcity;
  season: string | number;
}) => {
  const cardLevel = getCardLevel(input.xp);
  const scarcityBonus = getScarcityBonus(input.scarcity);
  const seasonBonus = getSeasonBonus(input.season);

  return {
    level: cardLevel.bonus,
    scarcity: scarcityBonus,
    season: seasonBonus,
    total: cardLevel.bonus + scarcityBonus + seasonBonus,
  };
};

const getScarcityBonus = (scarcity: Scarcity) => {
  switch (scarcity) {
    case Scarcity.SILVER:
      return 5;
    case Scarcity.GOLD:
      return 10;
    case Scarcity.PLATINUM:
      return 15;
    default:
      return 0;
  }
};

const getSeasonBonus = (season: string | number) => {
  switch (Number(season)) {
    case 2025:
      return 5;
    case 2024:
      return 2;
    default:
      return 0;
  }
};
