<script setup lang="ts">
import type { PropType } from "vue";
import { CurrencyDisplay, useCurrencyInput } from "vue-currency-input";
import { useI18n } from "vue-i18n";
import { DisplayCurrencyMode } from "~/application/enums/DisplayCurrencyMode.enum";
import useConvert from "~/composables/useConvert";

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  displayErr: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideConversion: {
    type: Boolean,
    required: false,
    default: true,
  },
  currencyMode: {
    type: String,
    required: false,
    default: "narrowSymbol",
  },
  valid: {
    type: Boolean,
    required: false,
    default: true,
  },
  mode: {
    type: String as PropType<DisplayCurrencyMode>,
    required: false,
    default: DisplayCurrencyMode.DOLLAR,
  },
  label: {
    type: String,
    required: false,
    default: "",
  },
  name: {
    type: String,
    required: false,
    default: "",
  },
  min: {
    type: Number,
    required: false,
    default: 0,
  },
  currency: {
    type: String,
    required: false,
    default: "",
  },
  max: {
    type: Number,
    required: false,
  },
});

const { locale } = useI18n();
const { usdToMtc, mtcToUsd } = useConvert;

const precision = props.mode === DisplayCurrencyMode.ETH ? 8 : 2;

const currencyInput = useCurrencyInput({
  currency: props.mode,
  locale: locale.value,
  currencyDisplay: props.currencyMode as CurrencyDisplay,
  hideCurrencySymbolOnFocus: false,
  valueRange: {
    min: props.min,
    max: props.max,
  },
  precision,
});

const inputRef = currencyInput.inputRef;

function setMax() {
  if (props.max) {
    currencyInput.setValue(props.max);
  }
}
</script>

<template>
  <label class="form-field-fg"
    ><span v-if="label.length">{{ label }}</span>
    <div class="flex flex-row w-full relative items-center">
      <div class="bg-slate-800 p-2 rounded-md w-full flex flex-row gap-2">
        <icon-fgc-polygon v-if="currency === 'MATIC'" class="w-9 h-9" />
        <icon-fgc-w-matic v-else-if="currency === 'WMATIC'" class="w-10 h-10" />
        <input ref="inputRef" class="w-full bg-transparent outline-none" />
        <button v-if="max" class="btn-text right-4" @click="setMax">max</button>
      </div>
      <span v-if="displayErr && !!modelValue" class="relative">
        <icon-akar-icons-circle-check
          v-if="!!modelValue && valid"
          class="absolute -left-9 top-[calc(50%-3px)] text-green"
          width="16px"
          height="16px"
        />
        <icon-akar-icons-circle-alert
          v-else
          class="absolute left-1 top-3 text-red-500"
          width="16px"
          height="16px"
        />
      </span>
    </div>
    <div
      v-if="!hideConversion && mode === DisplayCurrencyMode.DOLLAR"
      class="flex flex-row gap-2 py-1"
    >
      <span class="text-greyMed">≈ {{ usdToMtc(Math.max(min, modelValue)) }}</span
      ><icon-fgc-w-matic class="inline-icon" />
    </div>
    <div v-else-if="!hideConversion" class="flex flex-row gap-2 py-1">
      <span class="text-greyMed">≈ {{ mtcToUsd(modelValue, true) }}</span>
    </div>
  </label>
</template>

<style scoped></style>
