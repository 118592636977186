<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "~/shadcn/components/ui/sheet";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "~/shadcn/components/ui/drawer";
import { useNotificationCenter } from "~/stores/storeNotificationCenter";
import { X } from "lucide-vue-next";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const notificationCenter = useNotificationCenter();

const breakpoints = useBreakpoints(breakpointsTailwind);

const breakpointIsAboveLg = breakpoints.greaterOrEqual("lg");
</script>

<template>
  <component :is="breakpointIsAboveLg ? Sheet : Drawer" v-model:open="notificationCenter.open">
    <component
      :is="breakpointIsAboveLg ? SheetContent : DrawerContent"
      class="border-none p-0 outline-none bg-slate-950"
      hide-default-close-button
    >
      <component
        :is="breakpointIsAboveLg ? SheetHeader : DrawerHeader"
        class="border-b border-slate-600 lg:p-6 flex flex-row justify-between items-center"
      >
        <component :is="breakpointIsAboveLg ? SheetTitle : DrawerTitle">
          <span class="text-xl font-semibold">
            {{ t("drawers.notifications.notifications", "Notifications") }}
          </span>
          &nbsp;
          <span
            v-if="notificationCenter.unreadNotificationsCount > 0"
            class="bg-red-600 text-white rounded-full p-1 w-6 h-6 inline-flex items-center justify-center text-sm align-top"
          >
            {{ notificationCenter.unreadNotificationsCount }}
          </span>
          <!-- Sheet/Drawer Title -->
        </component>

        <button
          @click.stop="notificationCenter.markAllAsRead"
          class="font-semibold text-xs outline-none"
        >
          {{ t("drawers.notifications.markAllAsRead", "Mark all as read") }}
        </button>

        <X
          @click.stop.prevent="notificationCenter.open = false"
          aria-label="Close"
          class="w-4 cursor-pointer hidden lg:block"
        />

        <!-- Sheet/Drawer Header -->
      </component>

      <div id="notifications-list-container" class="flex flex-col items-stretch justify-start">
        <NotificationCenterItem
          v-for="notification in notificationCenter.allNotifications"
          :key="`${notification.id}-${notification.status}`"
          :notification="notification"
        />
      </div>

      <!-- Sheet/Drawer Content -->
    </component>
    <!-- Sheet/Drawer -->
  </component>
</template>
