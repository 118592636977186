import { RampInstantEventTypes, RampInstantSDK } from "@ramp-network/ramp-instant-sdk";
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { useStoreApp, NotifType } from "@/stores/storeApp";
import { isProd, LOGO_URL, maticMinAmount, rampApiKey } from "@/application/config";
import { pinia } from "~/main";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";

export const useRamp = () => {
  const storeApp = useStoreApp();

  const showOverlay = (mode: "desktop" | "mobile") => {
    const storeUser = useStoreUserV2(pinia);
    const ramp = new RampInstantSDK({
      hostAppName: "fungiball",
      fiatCurrency: "EUR",
      defaultAsset: storeUser.currentMaticBalance < maticMinAmount ? "MATIC_POL" : "MATIC_POL",
      swapAsset:
        storeUser.currentMaticBalance < maticMinAmount ? "MATIC_POL" : "MATIC_POL,MATIC_POL",
      url: isProd ? undefined : "https://app.demo.ramp.network",
      enabledFlows: ["ONRAMP"],
      hostLogoUrl: LOGO_URL,
      hostApiKey: rampApiKey,
      userAddress: storeUser.wallet.currentAccount!,
      userEmailAddress: storeUser.email,
      variant: mode,
    }).show();

    ramp.on(RampInstantEventTypes.PURCHASE_CREATED, (event) => {
      const status = event.payload.purchase.status;

      switch (status) {
        case "PAYMENT_FAILED":
          storeApp.displayNotification(NotifType.FUNDS_DEPOSIT_FAILED, {} as IAuctionItem);
          break;

        case "PAYMENT_EXECUTED":
          storeApp.displayNotification(NotifType.FUNDS_DEPOSIT_SUCCESS, {} as IAuctionItem);
          break;

        default:
          break;
      }
    });
  };

  return {
    showOverlay,
  };
};
