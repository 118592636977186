<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";
import { Check, CircleUserRound, DollarSignIcon, Baby, Gauge } from "lucide-vue-next";
import { useRouter } from "vue-router";
import { gameApi } from "~/api/game";
import genderMale from "~icons/fg/gender-male";
import genderFemale from "~icons/fg/gender-female";
import genderMixed from "~icons/fg/gender-mixed";
import { GameweekStatus } from "fungi-types";

const { t } = useI18n();
const router = useRouter();

const props = withDefaults(
  defineProps<{
    season: any;
    gameweekStatus: string;
    onboarding?: boolean;
    onboardingStep?: number;
  }>(),
  {
    onboarding: false,
    onboardingStep: 0,
  }
);

const competition = computed(() => {
  const { competition } = props.season;
  return competition;
});

const GenderIcon = computed(() => {
  const gender = competition.value.gender;
  if (gender === "male") {
    return genderMale;
  } else if (gender === "female") {
    return genderFemale;
  }
  return genderMixed;
});

const registration = computed(() => {
  const { user_registration } = props.season;
  return user_registration;
});

const hasRegistered = computed(() => registration.value?.status === "submitted");

const registrationOpen = computed(() => props.gameweekStatus === "upcoming");

const handleClickComp = async () => {
  if (props.onboarding) return;

  if (props.gameweekStatus === GameweekStatus.Upcoming) {
    try {
      await gameApi.registration.create.mutate({ season_id: props.season.id });
    } catch (e) {}

    router.push(Trans.i18nRoute({ name: "Subscription", params: { id: props.season.id } }));
  } else {
    router.push(Trans.i18nRoute({ name: "Leaderboard", params: { id: props.season.id } }));
  }
};

const CTAtext = computed(() => {
  if (hasRegistered.value && registrationOpen.value) {
    return t("Update");
  } else if (hasRegistered.value) {
    return t("pages.gameweek.cta.viewBuild");
  }
  return t("Register");
});
</script>

<template>
  <button
    @click="handleClickComp()"
    class="group text-left bg-black p-4 rounded-lg w-full flex flex-col relative overflow-hidden h-[300px] border-[1px] border-slate-700 hover:border-blue-500"
    :class="
      props.onboarding
        ? 'cursor-default hover:bg-black-950 hover:border-slate-700'
        : 'hover:bg-slate-950'
    "
  >
    <div class="w-full rounded-t-lg rounded-b-none grow overflow-hidden relative">
      <div
        v-if="
          ['dfe89dcc13b9', '25db8hr3d534', 'dfc425ccd006', '981fe89dcc13'].includes(competition.id)
        "
        class="absolute top-2 left-2 bg-yellow z-10 text-black text-xs uppercase px-2 py-1 font-medium tracking-wide rounded-md"
      >
        {{ t("new") }}
      </div>
      <template v-if="gameweekStatus === GameweekStatus.Ongoing">
        <template v-if="!props.onboarding">
          <div class="ctaText">
            <div class="text-center text-3xl">Live Leaderboard</div>
          </div>
        </template>
      </template>
      <template v-else-if="gameweekStatus === GameweekStatus.Upcoming && !props.onboarding">
        <div class="ctaText">
          <div class="text-center whitespace-nowrap">
            {{ CTAtext }}
          </div>
        </div>
      </template>
      <template v-else-if="!props.onboarding">
        <div class="ctaText">
          <div class="text-center text-3xl">Leaderboard</div>
        </div>
      </template>
      <div v-if="hasRegistered" class="registerMark">
        <Check :size="20" />
      </div>
      <img
        :src="`/images/courts/${season.surface}.jpg`"
        alt="Tennis court"
        class="w-full h-full transition-transform duration-500 object-bottom object-cover scale-110"
        :class="{ 'group-hover:scale-105': !onboarding }"
      />
    </div>
    <div class="py-2 flex flex-col gap-1 flex-wrap">
      <div class="lg:text-2xl text-lg capitalize tracking-wide pt-2">{{ season.name }}</div>
      <div class="flex flex-row gap-2 flex-wrap">
        <div
          :class="`bg-slate-800 rounded-md flex flex-row p-1 px-2 gap-1 text-sm items-center capitalize text-nowrap transition-all duration-300 ${props.onboardingStep >= 1 ? 'ring-2' : ''} ${props.onboardingStep === 1 ? 'animate-pulse ring-4' : ''}`"
        >
          <icon-fg-cards />{{ t(`pages.leaderboard.header.scarcity.${competition.scarcity}`) }}
        </div>
        <div
          :class="`bg-slate-800 rounded-md flex flex-row p-1 px-2 gap-1 text-sm items-center capitalize text-nowrap transition-all duration-300 ${props.onboardingStep >= 2 ? 'ring-2' : ''} ${props.onboardingStep === 2 ? 'animate-pulse ring-4' : ''}`"
        >
          <GenderIcon class="fill-white h-4" />
          {{ t(`pages.leaderboard.header.gender.${competition.gender}`) }}
        </div>
        <div
          v-if="competition.id === 'dfe89dcc13b9'"
          :class="`bg-slate-800 rounded-md flex flex-row p-1 px-2 text-sm items-center capitalize text-nowrap transition-all duration-300 ${props.onboardingStep >= 2 ? 'ring-2' : ''} ${props.onboardingStep === 2 ? 'animate-pulse ring-4' : ''}`"
        >
          <Baby class="h-4" /> 30+
        </div>
        <div
          v-else-if="competition.id === '25db8hr3d534'"
          :class="`bg-slate-800 rounded-md flex flex-row p-1 px-2 text-sm items-center capitalize text-nowrap transition-all duration-300 ${props.onboardingStep >= 2 ? 'ring-2' : ''} ${props.onboardingStep === 2 ? 'animate-pulse ring-4' : ''}`"
        >
          <Gauge class="h-4" /> 200
        </div>
        <div
          :class="`bg-slate-800 rounded-md flex flex-row p-1 px-2 gap-1 text-sm items-center capitalize text-nowrap ${props.onboardingStep >= 4 ? 'ring-2' : ''} ${props.onboardingStep === 4 ? 'animate-pulse ring-4' : ''}`"
        >
          <CircleUserRound class="h-4" /> {{ season.registrations_count }}
        </div>
        <div
          :class="`bg-slate-800 rounded-md flex flex-row p-1 px-2 gap-1 text-sm items-center capitalize text-nowrap ${props.onboardingStep >= 5 ? 'ring-2' : ''} ${props.onboardingStep === 5 ? 'animate-pulse ring-4' : ''}`"
        >
          <DollarSignIcon class="h-4" /> {{ season.prize_money }}
        </div>
      </div>
    </div>
  </button>
</template>

<style scoped>
.ctaText {
  /* @apply w-fit px-3 uppercase transition-all lg:text-5xl text-3xl font-display tracking-widest py-1 absolute z-10 left-1/2 top-1/3 lg:top-1/2 transform -translate-x-1/2 group-hover:opacity-80 opacity-0 group-hover:scale-105; */
  @apply px-3 uppercase transition-all lg:text-4xl md:text-2xl text-2xl font-display tracking-widest group-hover:opacity-80 opacity-0 group-hover:scale-105 absolute z-10 top-0 left-0 right-0 bottom-0 flex items-center justify-center w-full;
}

.registerMark {
  @apply absolute bg-green-500 z-40 rounded-full p-1 bottom-2 border-[2px] right-2;
}
</style>
