import mitt from "mitt";
import { mercureChannel } from "@/application/config";
import {
  MercureEventType,
  MercureEvents,
  AuctionEnd,
  AuctionUpdate,
  CantBidWithStripe,
  BalanceUpdate,
  NewPolRates,
  mercureTopicChannel as topic,
  InAppNotificationEventPayload,
} from "fungi-types";

export class MercureService {
  private static instance: MercureService;
  public emitter = mitt<MercureEvents>();
  hubUrl: URL;
  private eventSource: EventSource | null = null;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 50; // Increased from 20
  private baseRetryDelay: number = 1000;
  private isConnected: boolean = false;
  private lastErrorTime: number = 0;
  private errorCount: number = 0;
  private readonly errorThreshold: number = 3;
  private readonly errorTimeWindow: number = 60000; // 1 minute

  private constructor() {
    this.hubUrl = new URL(import.meta.env.VITE_MERCURE_URL);
    this.subscribeAll([]);
    this.connect();

    setInterval(() => this.checkConnection(), 15000);
  }

  public static getInstance(): MercureService {
    if (!MercureService.instance) {
      MercureService.instance = new MercureService();
    }
    return MercureService.instance;
  }

  private subscribeAll(additionalTopics: string[] = []) {
    this.hubUrl.searchParams.delete("topic");

    [
      topic.updateAuction,
      topic.updatePriceMatic,
      topic.newAuction,
      topic.endAuction,
      topic.updateBalance,
      topic.cantBidWithStripe,
      topic.polRates,
      topic.inAppNotification,
      topic.refreshNotifications,
      ...additionalTopics,
    ].forEach((topicPath) => {
      console.info(`[MERCURE] Subscribing to topic "/${mercureChannel}/${topicPath}"`);
      this.hubUrl.searchParams.append("topic", `/${mercureChannel}/${topicPath}`);
    });
  }

  private connect() {
    console.info("[Mercure] Connecting...");
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }

    try {
      this.eventSource = new EventSource(this.hubUrl);

      this.eventSource.onopen = () => {
        this.isConnected = true;
        this.reconnectAttempts = 0;
        this.errorCount = 0;
        console.info("[Mercure] Connected successfully");
      };

      this.eventSource.onmessage = (event) => {
        try {
          const payload = JSON.parse(event.data);
          const type = payload.type as MercureEventType;
          this.dispatch(type, payload);
        } catch (error) {
          console.error("[Mercure] Error processing message:", error);
        }
      };

      this.eventSource.onerror = (error) => {
        const currentTime = Date.now();
        if (currentTime - this.lastErrorTime < this.errorTimeWindow) {
          this.errorCount++;
        } else {
          this.errorCount = 1;
        }
        this.lastErrorTime = currentTime;

        console.error("[Mercure] Connection error:", error);
        this.handleConnectionError();
      };
    } catch (error) {
      console.error("[Mercure] Failed to establish connection:", error);
      this.handleConnectionError();
    }
  }

  private handleConnectionError() {
    this.isConnected = false;

    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }

    // if (this.reconnectAttempts < this.maxReconnectAttempts) {
    const delay = this.calculateRetryDelay();
    this.reconnectAttempts++;

    setTimeout(() => {
      this.connect();
    }, delay);
    // }
  }

  private calculateRetryDelay(): number {
    // Implement truncated exponential backoff with jitter
    const exponentialDelay = this.baseRetryDelay * Math.pow(1.5, this.reconnectAttempts);
    const maxDelay = 45000; // Increased from 30000
    const minDelay = 1000;
    const withoutJitter = Math.min(Math.max(exponentialDelay, minDelay), maxDelay);
    const jitter = Math.random() * (withoutJitter * 0.1); // 10% jitter
    return withoutJitter + jitter;
  }

  private checkConnection() {
    if (!this.isConnected && this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnect();
    }
  }

  private dispatch(type: MercureEventType, payload: any) {
    switch (type) {
      case MercureEventType.MATIC_PRICE:
        this.emitter.emit(type, payload.data as { price: number });
        break;
      case MercureEventType.AUCTION_UPDATE:
        this.emitter.emit(type, payload.data as AuctionUpdate);
        break;
      case MercureEventType.AUCTION_NEW:
        this.emitter.emit(type, payload.data as any);
        break;
      case MercureEventType.AUCTION_END:
        this.emitter.emit(type, payload.data as AuctionEnd);
        break;
      case MercureEventType.UPDATE_BALANCE:
        this.emitter.emit(type, payload.data as BalanceUpdate);
        break;
      case MercureEventType.CANT_BID_WITH_STRIPE:
        this.emitter.emit(type, payload.data as CantBidWithStripe);
        break;
      case MercureEventType.NEW_POL_RATES:
        this.emitter.emit(type, payload.data as NewPolRates);
        break;
      case MercureEventType.IN_APP_NOTIFICATION:
        this.emitter.emit(type, payload.data as InAppNotificationEventPayload);
        break;
      case MercureEventType.REFRESH_NOTIFICATIONS:
        this.emitter.emit(type, {});
        break;
      default:
        console.warn("[Mercure] Unhandled event type:", type);
        break;
    }
  }

  public reconnect() {
    this.reconnectAttempts = 0;
    this.connect();
  }

  public forceDisconnect() {
    this.isConnected = false;
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  public getConnectionStatus() {
    return {
      isConnected: this.isConnected,
      reconnectAttempts: this.reconnectAttempts,
      maxReconnectAttempts: this.maxReconnectAttempts,
      errorCount: this.errorCount,
    };
  }
}
