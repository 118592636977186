import Currency from "~/common/enums/Currency";

export function getCurrencySymbol(currency: string) {
  switch (currency) {
    case Currency.EUR:
      return "€";
    case Currency.USD:
      return "$";
    case "wmtc":
      return "wPOL";
    default:
      return currency;
  }
}
