<script setup lang="ts">
import { ref, watch, computed, onBeforeMount, onBeforeUnmount } from "vue";
import { X } from "lucide-vue-next";
import { closeNotification, ToastParams } from "~/shadcn/components/ui/sonner/custom-toast";
import { useStoreApp } from "~/stores/storeApp";
import { storeToRefs } from "pinia";

const props = defineProps<ToastParams & { toastId: number }>();

const storeApp = useStoreApp();
const { pauseNotificationTimers } = storeToRefs(storeApp);

// Use 75% of the duration to allow slider to reach 0% before the notification is dismissed by Sonner
const sliderDuration = (props.duration ?? 0) * 0.75;
const remainingTime = ref(sliderDuration);

let timer: NodeJS.Timeout | null = null;
const intervalTime = 10;

watch(pauseNotificationTimers, () => {
  if (pauseNotificationTimers.value) {
    clearInterval(timer ?? undefined);
  } else {
    timer = setInterval(() => {
      remainingTime.value -= intervalTime;

      if (remainingTime.value <= 0) {
        clearInterval(timer ?? undefined);
      }
    }, intervalTime);
  }
});

const remainingTimePercentage = computed(() => {
  return (100 * remainingTime.value) / (sliderDuration === 0 ? 1 : sliderDuration);
});

onBeforeMount(() => {
  if (!storeApp.pauseNotificationTimers && props.duration) {
    timer = setInterval(() => {
      remainingTime.value -= intervalTime;

      if (remainingTime.value <= 0) {
        clearInterval(timer ?? undefined);
      }
    }, intervalTime);
  }
});

onBeforeUnmount(() => {
  clearInterval(timer ?? undefined);
});
</script>

<template>
  <div
    class="flex flex-col items-stretch justify-between bg-transparent hover:bg-transparent"
    @click.once="props.callback"
    @mouseenter="storeApp.pauseAllNotificationTimers"
    @mouseleave="storeApp.resumeAllNotificationTimers"
  >
    <div
      class="grow flex flex-row items-center gap-2 border-full p-4 bg-slate-700 rounded-t-lg"
      :class="{
        'rounded-lg': props.pinned || remainingTimePercentage < 0,
      }"
    >
      <div v-if="$slots.icon || props.image" class="grid place-content-center min-w-8">
        <slot name="icon">
          <img v-if="props.image" :src="props.image" alt="Notification" class="max-h-24" />
        </slot>
      </div>

      <div class="text-white text-sm grow flex flex-col">
        <span class="font-semibold">
          {{ props.title ?? props.message }}
        </span>
        <p v-if="props.title">
          {{ props.message }}
        </p>
      </div>

      <button @click.stop.prevent="() => closeNotification(props.toastId)">
        <X />
      </button>
    </div>

    <div
      :class="{
        'bg-status-success-400/60': props.type === 'success',
        'bg-status-warning-400/60': props.type === 'warning',
        'bg-status-danger-400/60': props.type === 'error',
        'bg-primary-500/60': props.type === 'info',
      }"
      class="w-full h-1 relative rounded-b-full"
      v-if="!props.pinned"
    >
      <div
        class="absolute top-0 left-0 rounded-tr-full rounded-bl-full h-full"
        :class="{
          'bg-status-success-400': props.type === 'success',
          'bg-status-warning-400': props.type === 'warning',
          'bg-status-danger-400': props.type === 'error',
          'bg-primary-500': props.type === 'info',
        }"
        :style="`width: ${remainingTimePercentage}%`"
      ></div>
    </div>
  </div>
</template>
