<script setup lang="ts">
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { EPreferredLanguage } from "fungi-types";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import Trans from "~/modules/translations";
import { useId } from "vue";
import { useNotificationCenter } from "~/stores/storeNotificationCenter";

const userStore = useStoreUserV2();
const router = useRouter();
const i18n = useI18n();

const id = useId();

const handleLanguageChange = async (newLocale: string) => {
  console.log(`Switching to ${newLocale}`);
  await Trans.switchLanguage(newLocale);

  try {
    await userStore.updatePreferredLanguage(newLocale as EPreferredLanguage, true);

    // Replace notifications with the new ones with correct language
    useNotificationCenter().fetchNotifications().catch(console.error);

    await router.replace({ params: { locale: newLocale } });
  } catch (e) {
    console.error(`Error while changing user preferred language`);
  }
};
</script>

<template>
  <fieldset class="flex flex-col gap-1">
    <label :for="id" class="text-grey-30 text-xs">
      {{ i18n.t("drawers.profile.settingsView.preferences.language") }}
    </label>

    <Dropdown :id :list="Trans.supportedLocales" @selected="handleLanguageChange">
      <template #selected>
        {{ i18n.t(`languages.${i18n.locale.value}`, i18n.locale) }}
      </template>

      <template v-slot="item">
        {{ i18n.t(`languages.${item.listItem}`, item.listItem) }}
      </template>
    </Dropdown>
  </fieldset>
</template>
