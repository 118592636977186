<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { Notification, useNotificationCenter } from "~/stores/storeNotificationCenter";
import { intervalToDuration } from "date-fns";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { formatDurationShort } from "~/utils/dates";

const props = defineProps<{
  notification: Notification;
}>();

const isRead = props.notification.status === "READ";

const storeUser = useStoreUserV2();
const notificationCenter = useNotificationCenter();

const timeElapsed = ref(
  formatDurationShort(
    intervalToDuration({
      start: props.notification.createdAt,
      end: new Date(),
    }),
    storeUser.preferredLanguage ?? "en"
  )
);

let timeElapsedInterval: NodeJS.Timeout;

onBeforeMount(() => {
  timeElapsedInterval = setInterval(() => {
    timeElapsed.value = formatDurationShort(
      intervalToDuration({
        start: new Date(props.notification.createdAt),
        end: new Date(),
      }),
      storeUser.preferredLanguage ?? "en"
    );
  }, 60_000);
});

onBeforeUnmount(() => {
  clearInterval(timeElapsedInterval);
});
</script>

<template>
  <div
    :class="{
      'p-1 border-b border-slate-700': true,
      'bg-slate-900 hover:bg-slate-800': !isRead,
      'bg-slate-950 hover:bg-slate-800': isRead,
    }"
    :data-status="notification.status"
    @click.stop="notificationCenter.markAsRead(notification.id)"
  >
    <div
      class="px-4 py-5 h-full w-full flex flex-row justify-start items-stretch gap-3"
      :class="{
        'border-l-4 border-primary-60': !isRead,
      }"
    >
      <div v-if="notification.image">
        <img :src="notification.image" :alt="notification.title" class="max-w-16" />
      </div>

      <div class="grow flex flex-col items-stretch justify-start text-sm">
        <span class="font-semibold">{{ notification.title }}</span>

        <p class="font-normal grow" v-html="notification.description"></p>

        <div class="flex flex-row-reverse items-center justify-between">
          <span>{{ timeElapsed }}</span>

          <a
            v-if="notification.link && notification.buttonText"
            :href="notification.link"
            target="_blank"
            class="bg-primary-60 text-white rounded-md px-2 py-1.5 text-xs"
            @click.stop="notificationCenter.markAsRead(notification.id)"
          >
            {{ notification.buttonText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
