"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Edition = exports.CardType = exports.NftType = exports.SkillLevel = exports.TypeGame = exports.Gender = exports.Skill = exports.CourtType = exports.NftStatus = void 0;
var NftStatus;
(function (NftStatus) {
    NftStatus["CREATED"] = "created";
    NftStatus["FOR_SALE"] = "forSale";
    NftStatus["FOR_REWARD"] = "forReward";
    NftStatus["CIRCULATING"] = "circulating";
    NftStatus["UNSOLD"] = "unsold";
})(NftStatus || (exports.NftStatus = NftStatus = {}));
var CourtType;
(function (CourtType) {
    CourtType["CLAY"] = "clay";
    CourtType["HARD"] = "hard";
    CourtType["GRASS"] = "grass";
    CourtType["MULTI"] = "multi";
})(CourtType || (exports.CourtType = CourtType = {}));
var Skill;
(function (Skill) {
    Skill["POWER"] = "power";
    Skill["SERVICE"] = "service";
    Skill["RETURN"] = "return";
    Skill["MENTAL"] = "mental";
})(Skill || (exports.Skill = Skill = {}));
var Gender;
(function (Gender) {
    Gender["MALE"] = "Male";
    Gender["FEMALE"] = "Female";
})(Gender || (exports.Gender = Gender = {}));
var TypeGame;
(function (TypeGame) {
    TypeGame["SIMPLE"] = "Simple";
    TypeGame["DOUBLE"] = "Double";
})(TypeGame || (exports.TypeGame = TypeGame = {}));
var SkillLevel;
(function (SkillLevel) {
    SkillLevel["ONE"] = "1";
    SkillLevel["TWO"] = "2";
    SkillLevel["THREE"] = "3";
})(SkillLevel || (exports.SkillLevel = SkillLevel = {}));
var NftType;
(function (NftType) {
    NftType["SKILL"] = "skill";
    NftType["F2P"] = "f2p";
    NftType["EQUIPMENT"] = "equipment";
})(NftType || (exports.NftType = NftType = {}));
var CardType;
(function (CardType) {
    CardType["NftSkills"] = "nft_skills";
    CardType["NftEquipment"] = "nft_equipment";
})(CardType || (exports.CardType = CardType = {}));
var Edition;
(function (Edition) {
    Edition["STANDARD"] = "standard";
    Edition["SPECIAL"] = "special";
    Edition["F2P"] = "f2p";
})(Edition || (exports.Edition = Edition = {}));
