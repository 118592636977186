<script setup lang="ts">
import { ref } from "vue";
import type { OnClickOutsideHandler } from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
const showDropdown = ref(false);

const props = defineProps({
  chevron: {
    type: Boolean,
    default: true,
  },
  list: {
    type: Array<string>,
  },
});

const emit = defineEmits<{
  (event: "closed"): void;
  (event: "selected", value: string): void;
}>();

const dropdownHandler: OnClickOutsideHandler = () => {
  showDropdown.value = false;
  emit("closed");
};

function selectHandler(el: string) {
  showDropdown.value = false;
  emit("selected", el);
}
</script>

<template>
  <div class="flex flex-col items-stretch rounded-md bg-neutral-900">
    <div class="cursor-pointer items-center" @click="showDropdown = true">
      <div
        class="flex justify-between items-center gap-2 px-f3 py-f1 hover:bg-primary-110/50 text-grey-60"
        :class="showDropdown ? 'bg-primary-110/50' : ''"
      >
        <slot name="selected" />

        <icon-fg-chevron v-if="chevron && !showDropdown" class="inline-icon-xs" />
        <icon-fg-chevron v-else-if="chevron" class="inline-icon-xs rotate-180" />
      </div>
    </div>

    <div class="relative w-full" v-if="showDropdown" v-on-click-outside="dropdownHandler">
      <div
        class="left-0 top-0 z-10 absolute flex flex-col items-start w-full bg-primary-110/90 py-f1 gap-f1 shadow-md"
      >
        <div
          v-for="listItem in list"
          :key="`el-${listItem}`"
          :value="listItem"
          class="cursor-pointer w-full justify-center hover:bg-primary-10/15 last:rounded-b-lg"
          @click="selectHandler(listItem)"
        >
          <div class="flex items-center gap-2 px-f3 py-f1">
            <slot :listItem="listItem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
