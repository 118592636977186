<script setup lang="ts">
import { computed, inject } from "vue";
import { BigNumber } from "ethers";
import { useI18n } from "vue-i18n";
import Stat from "~/components/stats/Stat.vue";
import useConvert from "~/composables/useConvert";
import DetailsSection from "~/components/card-details/DetailsSection.vue";
import PeriodSelector from "~/components/card-details/PeriodSelector.vue";
import ScrollableLineTimeChart from "~/components/charts/ScrollableLineTimeChart.vue";
import { PriceTimeframe } from "~/components/card-details/types/StatsTimeframes";
import { useCardDetails } from "~/components/card-details/use-card-details";
import { storeToRefs } from "pinia";
import { Skill } from "fungi-types";

const { weiToMatic } = useConvert;

const { t } = useI18n();

const cardDetails = useCardDetails();
const { priceHistory, selectedSkill } = storeToRefs(cardDetails);

const priceStats = computed(() => {
  const average = weiToMatic(priceHistory.value.stats.average);
  const last =
    priceHistory.value.data.length != 0
      ? weiToMatic(BigNumber.from(priceHistory.value.data.at(0)!.price))
      : null;

  return { average, last };
});

const priceHistoryData = computed(() => {
  return priceHistory.value.data
    .map((dataPoint) => ({
      date: new Date(dataPoint.timestamp),
      value: weiToMatic(dataPoint.price),
    }))
    .reverse();
});

const priceHistoryLabels = computed(() => {
  return priceHistory.value.data.map(({ timestamp }) => timestamp).reverse();
});

const pricePeriodOptions = computed(() => [
  { label: `1 ${t("cardDetails.periods.m", "m")}`, value: { month: 1 } },
  { label: `3 ${t("cardDetails.periods.m", "m")}`, value: { months: 3 } },
  { label: `1 ${t("cardDetails.periods.y", "y")}`, value: { years: 1 } },
  { label: t("cardDetails.periods.all", "All"), value: undefined },
]);

const skillsColors = inject<Record<Skill, string>>("skills-colors")!;
const skillsGradients = inject<Record<Skill, [string, string]>>("skills-gradients")!;
</script>

<template>
  <DetailsSection :name="t('cardDetails.marketplaceInfo.title')">
    <template #time-picker>
      <PeriodSelector
        :options="pricePeriodOptions"
        :value="cardDetails.priceTimeframe"
        :active-class="`bg-skill-${selectedSkill!}`"
        @change="
          (val) => {
            cardDetails.changePriceTimeframe(val as PriceTimeframe);
          }
        "
      />
    </template>
    <template #default>
      <StatsSection>
        <Stat
          :name="t('cardDetails.marketplaceInfo.averagePrice', 'Average price')"
          classes="bg-opacity-100"
          class="grow md:max-w-[50%]"
        >
          <template #default>
            <div class="flex items-center gap-2">
              <span class="align-middle">{{ priceStats.average?.toFixed(2) }}</span>
              <IconFgcWMatic />
            </div>
          </template>
        </Stat>

        <Stat :name="t('cardDetails.marketplaceInfo.lastSale', 'Last sale')" class="grow">
          <div class="flex items-center gap-2">
            <template v-if="priceStats.last != null">
              <span class="align-middle">{{ priceStats.last?.toFixed(2) }}</span>
              <IconFgcWMatic />
            </template>
            <template v-else> - </template>
          </div>
        </Stat>
      </StatsSection>

      <h3 class="font-semibold text-sm">
        {{ t("cardDetails.marketplaceInfo.chartTitle", "Price change over time") }}
      </h3>

      <div v-if="priceHistoryData" class="h-[33vh] px-2 py-4 border-slate-700 border rounded-md">
        <ScrollableLineTimeChart
          :line-color="skillsColors[selectedSkill!]"
          :data="priceHistoryData"
          :labels="priceHistoryLabels"
          :xTicks="12"
          :yTicks="4"
          :gradient-color0="skillsGradients[selectedSkill!][0]"
          :gradient-color1="skillsGradients[selectedSkill!][1]"
        >
          <span class="font-regular text-sm">
            {{
              t("cardDetails.marketplaceInfo.noDataForPeriod", "No data for the selected period")
            }}
          </span>
        </ScrollableLineTimeChart>
      </div>
    </template>
  </DetailsSection>
</template>
