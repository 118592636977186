<script setup lang="ts">
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { storeToRefs } from "pinia";

const storeUserV2 = useStoreUserV2();
const { username, email } = storeToRefs(storeUserV2);
</script>

<template>
  <div
    class="flex flex-row justify-start items-center gap-2 bg-slate-900 rounded-lg px-5 py-3 max-w-full"
  >
    <img class="rounded-full w-8 md:w-10 lg:w-12" src="/images/avatar.png" alt="avatar" />

    <div class="flex flex-col space-between items-start min-w-0 max-w-full">
      <span class="text-white font-semibold text-lg text-ellipsis max-w-full overflow-hidden">
        {{ username || "Username" }}
      </span>

      <span
        class="min-w-0 text-slate-400 font-normal text-sm text-ellipsis max-w-full overflow-hidden"
      >
        {{ email || "nom.prenom@gmail.com" }}
      </span>
    </div>
  </div>
</template>
