<script setup lang="ts">
const props = defineProps<{
  title?: string;
}>();
</script>

<template>
  <section>
    <h1 class="font-semibold text-lg pb-2" v-if="props.title">
      {{ props.title }}
    </h1>
    <slot />
  </section>
</template>
