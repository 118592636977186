<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCardDetails } from "~/components/card-details/use-card-details";
import CountryFlagImg from "~/components/common/CountryFlagImg.vue";
import { getCountryNameI18n } from "~/utils/countryIso";
import { getAge } from "~/utils/dates";
const { t, locale } = useI18n();

const cardDetails = useCardDetails();
const { selectedCard } = storeToRefs(cardDetails);

const card = computed(() => selectedCard.value?.card);
const badges = computed(() => {
  if (!card.value) return [];

  const countryName = getCountryNameI18n(card.value.countryIso!, locale.value);
  const scarcity = t(`scarcities.${card.value.scarcity}`);
  const season = card.value.season.toString();
  const age = `${getAge(card.value.dateOfBirth)} ${t("cardDetails.yearsOld")}`;

  return [countryName, scarcity, season, age].map(
    (badge) => badge.charAt(0).toUpperCase() + badge.slice(1)
  );
});
</script>

<template>
  <div class="flex flex-col gap-2 items-start" v-show="card != null">
    <div class="flex flex-row items-center gap-4">
      <h1 class="font-display text-xl md:lg:text-2xl lg:text-4xl uppercase">
        {{ card!.firstName! }} {{ card!.lastName! }}
      </h1>
      <div v-if="card?.countryIso">
        <CountryFlagImg :country-iso="card!.countryIso!" class="w-6 lg:w-6" />
      </div>
    </div>
    <ul class="flex flex-row items-center justify-start gap-1 lg:gap-2">
      <li
        v-for="(badge, idx) in badges"
        :key="idx"
        class="bg-white/10 border-slate-600 border rounded-full px-3 py-1 text-xs whitespace-nowrap cursor-default"
      >
        {{ badge }}
      </li>
    </ul>
  </div>
</template>
