import { ref } from "vue";
import { userApi } from "~/api/user";
import type { UserEquipment } from "~/common/interfaces/IUser";
import i18n from "~/modules/i18n";
import { showNotification, ToastType } from "~/shadcn/components/ui/sonner/custom-toast";

const selectedEquipment = ref<UserEquipment | null>(null);
const nextLevel = ref<number | null>(null);

export const useCraftEquipment = () => {
  const setNextLevel = (equipments: Array<UserEquipment>) => {
    const totalEquipments = equipments.reduce((acc, equipment) => {
      if (equipment.level === 1) {
        return acc + equipment.count;
      }
      if (equipment.level === 2) {
        return acc + 25;
      }
      if (equipment.level === 3) {
        return acc + 50;
      }
      if (equipment.level === 4) {
        return acc + 75;
      }
      return acc;
    }, 0);

    nextLevel.value = Math.floor(totalEquipments / 25) + 1;
  };
  const setSelectedEquipment = (equipment: UserEquipment | null) => {
    selectedEquipment.value = equipment;
  };

  const clearSelectedEquipment = () => {
    selectedEquipment.value = null;
  };

  const craftNewEquipment = async (identifier: string) => {
    try {
      const equipment = await userApi.craftEquipment(identifier, selectedEquipment.value!.title);

      if (!equipment) {
        return null;
      }

      selectedEquipment.value = {
        ...selectedEquipment.value,
        title: selectedEquipment.value!.title!,
        brand: selectedEquipment.value!.brand!,
        edition: selectedEquipment.value!.edition!,
        image: equipment.image!,
        model_url: selectedEquipment.value!.model_url!,
        owner: selectedEquipment.value!.owner!,
        bonus: equipment.bonus!,
        level: equipment.level!,
        card_type: selectedEquipment.value!.card_type!,
        count: 1,
      };
      return equipment;
    } catch (e) {
      showNotification({
        type: ToastType.ERROR,
        message: i18n.global.t("modals.upgrade-equipment.error"),
      });
      return null;
    }
  };

  return {
    nextLevel,
    selectedEquipment,
    setSelectedEquipment,
    clearSelectedEquipment,
    craftNewEquipment,
    setNextLevel,
  };
};
