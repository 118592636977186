<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DisplayCurrencyMode } from "~/application/enums/DisplayCurrencyMode.enum";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreContract } from "~/stores/storeContract";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import useConvert from "~/composables/useConvert";
import { maticMinAmount, minWrapMatic } from "~/application/config";
import {
  isProd,
  marketpaceContractMainnet,
  marketpaceContractMumbai,
  polygonscanUrl,
  wmaticContractMainnet,
  wmaticContractMumbai,
} from "@/application/config";
import Modal from "@/components/modals/ModalContainer.vue";
import { showNotification, ToastType } from "~/shadcn/components/ui/sonner/custom-toast";

const { mtcToUsd, weiToMatic } = useConvert;
const storeApp = useStoreApp();
const storeUser = useStoreUserV2();
const storeContract = useStoreContract();
const { t } = useI18n();

const gasFee = ref(0);
const amount = ref(0);
const modeMatic = ref(true);
const refresh = ref(0);

onMounted(() => {
  gasEstimation();
  storeUser.refreshBalance();
});

const balance = computed((): number => {
  const n = modeMatic.value ? storeUser.currentMaticBalance : storeUser.availableWMaticBalance;
  return n;
});
const notEnough = computed((): boolean => {
  const n = modeMatic.value ? storeUser.currentMaticBalance : storeUser.availableWMaticBalance;
  return n < minWrapMatic;
});

const convertedAmount = computed((): number => {
  return Math.max(0, amount.value - gasFee.value);
});
const etherscanUrl = computed((): string => {
  return `${polygonscanUrl}tx/${storeContract.lastTransactionHash}`;
});
const smartContractWETH = computed((): string => {
  return `${polygonscanUrl}address/${isProd ? wmaticContractMainnet : wmaticContractMumbai}`;
});
const smartContractAuction = computed((): string => {
  return `${polygonscanUrl}address/${isProd ? marketpaceContractMainnet : marketpaceContractMumbai}`;
});

async function gasEstimation() {
  const targetAmount = amount.value ? amount.value.toString() : balance.value.toString();
  const gasEst = await storeContract.getGasFees(targetAmount);
  gasFee.value = weiToMatic(gasEst.maxPriorityFeePerGas);
}

async function toggleMode() {
  refresh.value++;
  modeMatic.value = !modeMatic.value;
}
async function swap() {
  storeApp.setActionInProgress(true);
  let targetAmount = amount.value - gasFee.value;
  try {
    if (modeMatic.value) {
      await storeContract.convertToWmtc(targetAmount.toString());
    } else {
      await storeContract.convertToMatic(targetAmount.toString());
    }
    await storeUser.refreshBalance();
    storeApp.setActionInProgress(false);
    showNotification({
      type: ToastType.SUCCESS,
      message: modeMatic.value
        ? t("messages.transaction-success-wmtc", { n: targetAmount })
        : t("messages.transaction-success-mtc", { n: targetAmount }),
    });
  } catch (error) {
    storeApp.setActionInProgress(false);
  }
}

const emit = defineEmits(["onClose"]);

const handlOnClose = () => {
  emit("onClose");
};

const props = withDefaults(
  defineProps<{
    show: boolean;
  }>(),
  {
    show: false,
  }
);
</script>

<template>
  <Modal
    :show="show"
    @onClose="handlOnClose"
    :title="modeMatic ? t('modals.swap.mtc2wmtc') : t('modals.swap.wmtc2mtc')"
  >
    <div class="flex flex-col gap-4">
      <p class="whitespace-pre-line mb-10 text-sm text-greyLight text-justify">
        {{ modeMatic ? t("modals.swap.swapToWETHDesc") : t("modals.swap.swapToETHDesc") }}
      </p>
      <div class="flex flex-row items-center justify-center gap-5 w-full text-sm">
        <div
          v-if="storeApp.actionInProgress"
          class="flex flex-col items-center justify-center w-full gap-2"
        >
          <span class="bg-black/50 px-2 py-1"
            >{{ modeMatic ? t("button.swapToWMATIC") : t("button.swapToMATIC") }}
            {{ convertedAmount.toFixed(8) }}</span
          >
          <WaitingMessage show class="text-xl w-full justify-center text-white" />
          <a
            v-if="storeContract.lastTransactionHash"
            class="btn-link"
            :href="etherscanUrl"
            target="_blank"
            >{{ t("links.transaction-polygonscan") }}
            <icon-mi:external-link class="inline" />
          </a>
        </div>

        <div v-else class="flex flex-col items-start justify-center w-full">
          <div class="flex flex-col md:flex-row justify-between w-full">
            <span class="font-bold uppercase">{{ t("modals.swap.from") }}</span>
            <span class="uppercase">{{ t("modals.swap.balance") }} {{ balance }}</span>
          </div>
          <div class="flex flex-row items-start gap-3 w-full">
            <CurrencyInput
              :currency="modeMatic ? 'MATIC' : 'WMATIC'"
              :key="refresh + storeUser.availableWMaticBalance + storeUser.currentMaticBalance"
              v-model="amount"
              currency-mode="hidden"
              class="w-full"
              :mode="DisplayCurrencyMode.ETH"
              :min="0"
              :max="Math.max(0, balance - minWrapMatic * 1.01)"
            />
          </div>

          <div class="w-full my-6">
            <div class="bg-greyDark w-full h-px mt-[24px]" />
            <div
              class="h-[48px] w-[48px] -mt-[24px] rounded-full border border-grey bg-grey-80/50 mx-auto hover:bg-primary cursor-pointer"
              @click="toggleMode"
            >
              <icon-cil:swap-vertical class="inline h-full w-full p-3" />
            </div>
          </div>

          <div class="flex flex-col md:flex-row justify-between w-full">
            <span class="font-bold uppercase">{{ t("modals.swap.to") }}</span>
            <span class="uppercase"
              >{{ t("modals.swap.balance") }}
              {{
                modeMatic ? storeUser.availableWMaticBalance : storeUser.currentMaticBalance
              }}</span
            >
          </div>
          <div class="flex flex-row items-start gap-3 w-full">
            <div class="bg-slate-800 p-2 rounded-md w-full flex flex-row gap-2 items-center">
              <icon-fgc-w-matic v-if="modeMatic" class="w-9 h-9" />
              <icon-fgc-polygon v-else class="w-10 h-10" />
              <div class="text-lg w-full text-left">
                {{ convertedAmount.toFixed(8) }}
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full gap-1 p-1">
            <span class="text-left w-full">≈ {{ mtcToUsd(convertedAmount, true) }}</span>
          </div>
          <div
            v-if="storeUser.currentMaticBalance < maticMinAmount"
            class="font-light text-sm flex items-center gap-1 px-2 py-1 flex-row bg-black/60 rounded-md drop-shadow"
          >
            <icon-fg-info class="text-primary inline-icon" />
            {{ $t("pages.myAccount.needMatic") }}
          </div>
        </div>
      </div>
      <ButtonPrimary
        :blue="true"
        :disabled="storeApp.actionInProgress || notEnough"
        v-show="!storeApp.actionInProgress"
        @click="swap"
      >
        {{ modeMatic ? t("modals.swap.swapToWMATIC") : t("modals.swap.swapToMATIC") }}
      </ButtonPrimary>
      <span class="text-center w-full justify-center"
        >{{ t("modals.swap.estGas") }}
        {{ gasFee.toLocaleString("fullwide", { maximumSignificantDigits: 10 }) }}</span
      >
      <p
        class="whitespace-pre-line flex flex-row text-sm text-center w-full items-center justify-center flex-wrap gap-4 py-3"
      >
        <a class="btn-link" :href="smartContractWETH" target="_blank"
          >{{ t("links.contractWMATIC") }}
          <icon-mi:external-link class="inline" />
        </a>
        <a class="btn-link" :href="smartContractAuction" target="_blank"
          >{{ t("links.contractAuction") }}
          <icon-mi:external-link class="inline" />
        </a>
      </p>
    </div>
  </Modal>
</template>

<style scoped></style>
