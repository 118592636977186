<script setup lang="ts"></script>

<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 1.33989C17.0083 2.21075 18.2629 3.46042 19.1398 4.96519C20.0167 6.46997 20.4854 8.17766 20.4994 9.91923C20.5135 11.6608 20.0725 13.3758 19.22 14.8946C18.3676 16.4133 17.1332 17.6831 15.6392 18.5782C14.1452 19.4733 12.4434 19.9627 10.7021 19.998C8.96083 20.0332 7.24055 19.6131 5.71156 18.7791C4.18256 17.9452 2.89787 16.7264 1.98467 15.2434C1.07146 13.7604 0.561409 12.0646 0.505 10.3239L0.5 9.99989L0.505 9.67589C0.561003 7.94888 1.06355 6.26585 1.96364 4.79089C2.86373 3.31592 4.13065 2.09934 5.64089 1.25977C7.15113 0.420205 8.85315 -0.0137108 10.581 0.000330246C12.3089 0.0143713 14.0036 0.47589 15.5 1.33989ZM14.207 7.29289C14.0348 7.12072 13.8057 7.01729 13.5627 7.002C13.3197 6.98672 13.0794 7.06064 12.887 7.20989L12.793 7.29289L9.5 10.5849L8.207 9.29289L8.113 9.20989C7.92059 9.06075 7.68037 8.98692 7.43741 9.00225C7.19444 9.01757 6.96541 9.12101 6.79326 9.29315C6.62112 9.4653 6.51768 9.69433 6.50235 9.9373C6.48703 10.1803 6.56086 10.4205 6.71 10.6129L6.793 10.7069L8.793 12.7069L8.887 12.7899C9.06237 12.926 9.27803 12.9998 9.5 12.9998C9.72197 12.9998 9.93763 12.926 10.113 12.7899L10.207 12.7069L14.207 8.70689L14.29 8.61289C14.4393 8.42049 14.5132 8.18024 14.4979 7.93721C14.4826 7.69419 14.3792 7.46509 14.207 7.29289Z"
      fill="#55BF5C"
    />
  </svg>
</template>
