<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { getCardLevel } from "fungi-utils/cardXp";
import { computed } from "vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    xp: number;
  }>(),
  {
    xp: 0,
  }
);

const cardLevel = computed(() => {
  return getCardLevel(props.xp);
});

const width = computed(() => {
  return `${cardLevel.value.progression}%`;
});
</script>

<template>
  <div class="w-full p-1 rounded-md py-1 text-xs gap-1 flex flex-col">
    <div class="w-full flex flex-row justify-between text-neutral-200">
      <div>{{ t("level") }} {{ cardLevel.currentLevel }}</div>
      <div class="flex flex-row items-center">
        <span>{{ cardLevel.currentXp }}</span>
        <span class="text-neutral-200 flex flex-row items-center"
          ><span class="text-[0.7em] px-0.5">/</span><span>{{ cardLevel.xpToNextLevel }}</span>
          <span class="pl-1">XP</span></span
        >
      </div>
    </div>
    <div class="bg-neutral-950 rounded-md h-1 w-full overflow-hidden">
      <div :style="{ width: width }" class="bg-blue-600 h-1"></div>
    </div>
  </div>
</template>
