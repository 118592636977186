<script setup lang="ts">
import { useStoreApp } from "~/stores/storeApp";
import Modal from "@/components/modals/ModalContainer.vue";
import { useCraftEquipment } from "~/composables/useCraftEquipment";
import { ref } from "vue";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import Loader from "~/components/dashboard/Loader.vue";
import ButtonPrimary from "~/components/ButtonPrimary.vue";
import LevelIndicator from "~/components/common/LevelIndicator.vue";
import { useStoreEquipmentDeck } from "~/stores/storeEquipmentDeck";

withDefaults(
  defineProps<{
    show: boolean;
  }>(),
  {
    show: false,
  }
);

const storeUser = useStoreUserV2();

const { selectedEquipment, clearSelectedEquipment, craftNewEquipment, nextLevel } =
  useCraftEquipment();
const { getUserEquipment } = useStoreEquipmentDeck();
const isLoading = ref(false);
const hasUpgraded = ref(false);

const handleOnClose = () => {
  useStoreApp().showEquipmentsCraftModal = false;
  clearSelectedEquipment();
  hasUpgraded.value = false;
};
const upgradeEquipment = async () => {
  isLoading.value = true;
  const newEquipment = await craftNewEquipment(storeUser.wallet.currentAccount);
  isLoading.value = false;
  if (!newEquipment) {
    return;
  }
  await getUserEquipment(storeUser.wallet.currentAccount);
  hasUpgraded.value = true;
};
</script>

<template>
  <Modal v-if="selectedEquipment" :show="show" @onClose="handleOnClose" :bgColor="''" size="4xl">
    <div class="relative flex flex-col lg:gap-24 lg:flex-row p-5 lg:p-14 gap-6 justify-around">
      <Loader
        v-if="isLoading"
        class="bg-slate-950/50 backdrop-blur-md z-10 flex items-center justify-center"
      />
      <div v-if="hasUpgraded" class="flex flex-col gap-10 place-content-center">
        <div class="flex flex-col gap-2 items-center">
          <span class="text-3xl font-semibold leading-8">{{
            $t("pages.myEquipments.modal.congratulations")
          }}</span>
          <span class="text-base"
            >{{ $t("pages.myEquipments.modal.progress") }} {{ nextLevel }}.
          </span>
        </div>
        <ButtonPrimary class="hidden lg:block px-10" @click="handleOnClose">
          {{ $t("pages.myEquipments.modal.close") }}
        </ButtonPrimary>
      </div>
      <!-- Mobile -->
      <div v-if="!hasUpgraded" class="block lg:hidden">
        <div class="flex flex-col gap-2 place-content-center">
          <span class="text-2xl items-center whitespace-nowrap font-semibold leading-8">{{
            $t("pages.myEquipments.modal.title")
          }}</span>
          <span class="text-base">{{ $t("pages.myEquipments.modal.description") }} </span>
        </div>
      </div>
      <!-- End Mobile -->

      <div class="w-full items-center justify-center flex">
        <NftSkill class="max-w-52 lg:max-w-96" :nft="selectedEquipment" :background="false">
          <template #footer-data>
            <CardDataFooterSkeleton>
              <template #header>
                <CardDataFooterEquipment :nft="selectedEquipment" />
              </template>
            </CardDataFooterSkeleton>
          </template>
        </NftSkill>
      </div>
      <!-- Mobile  -->
      <div v-if="!hasUpgraded" class="flex flex-col lg:hidden gap-2">
        <LevelIndicator :current-level="selectedEquipment.level" />
      </div>
      <div class="flex justify-center lg:hidden">
        <ButtonPrimary :blue="true" v-if="!hasUpgraded" class="w-full" @click="upgradeEquipment">
          {{ $t("pages.myEquipments.modal.upgradeLevel") }} {{ nextLevel }}
        </ButtonPrimary>
      </div>
      <!-- End Mobile -->
      <div v-if="!hasUpgraded" class="hidden lg:flex lg:flex-col font-sans font-normal gap-10">
        <div class="flex flex-col gap-2">
          <span class="text-3xl whitespace-nowrap font-semibold leading-8">{{
            $t("pages.myEquipments.modal.title")
          }}</span>
          <span class="text-base">{{ $t("pages.myEquipments.modal.description") }} </span>
        </div>
        <LevelIndicator :current-level="selectedEquipment.level" />
        <ButtonPrimary :blue="true" class="w-full justify-center" @click="upgradeEquipment">
          {{ $t("pages.myEquipments.modal.upgradeLevel") }} {{ nextLevel }}
        </ButtonPrimary>
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.footer {
  @apply rounded-md bg-grey-40/10 w-full overflow-hidden;
}
</style>
