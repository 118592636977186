<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: false,
    default: "",
  },
  type: {
    type: String,
    required: false,
    default: "text",
  },
  displayErr: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  valid: {
    type: Boolean,
    required: false,
    default: true,
  },
  label: {
    type: String,
    required: false,
    default: "",
  },
  placeholder: {
    type: String,
    required: false,
    default: "",
  },
  autocomplete: {
    type: String,
    required: false,
    default: "",
  },
  name: {
    type: String,
    required: false,
    default: "",
  },
  inputClass: {
    type: String,
    required: false,
    default: "",
  },
});

const emit = defineEmits<{
  (event: "change", name: string, value: string): void;
}>();

function evtHandler(evt: Event) {
  const target = evt.target as HTMLInputElement;
  emit("change", props.name, target.value);
}
const showOutline = ref(false);
const handleFocused = (state: boolean) => (showOutline.value = state);
</script>

<template>
  <label
    ><span v-if="label.length" class="mb-2 font-bold">{{ label }}</span>
    <div
      class="flex flex-row w-full bg-slate-800 rounded-md justify-center border-[1px] border-transparent transition-colors duration-300"
      :class="`${showOutline ? '!border-blue-500' : ''}`"
    >
      <input
        :disabled="disabled"
        :type="type"
        @focus="handleFocused(true)"
        @blur="handleFocused(false)"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :name="name"
        :class="`form-input-fg text-gray-400 bg-transparent text-base !outline-none w-full ${inputClass}  ${disabled ? 'cursor-not-allowed ' : ''}`"
        :value="value"
        @change="evtHandler"
        @input="evtHandler"
      />
      <div v-if="displayErr && !!value" class="px-2 flex justify-center items-center">
        <icon-akar-icons-circle-check
          v-if="!!value && valid"
          class="text-green"
          width="24px"
          height="24px"
        />
        <icon-akar-icons-circle-alert v-else class="text-red-500" width="24px" height="24px" />
      </div>
    </div>
  </label>
</template>

<style scoped></style>
