<script setup lang="ts">
import Footer from "../Footer.vue";
import SubHeader from "../subHeaders/index.vue";
import { useRoute } from "vue-router";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { storeToRefs } from "pinia";
import MobileNavBar from "./NavBarMobile.vue";
import { useStoreFlashSale } from "~/stores/storeFlashSale";
import FlashSaleBanner from "./NavBannerFlashSale.vue";
import NavMenu from "./NavBarDesktopMenu.vue";

const storeFlashSale = useStoreFlashSale();
const { showFlashSaleBanner } = storeToRefs(storeFlashSale);

const route = useRoute();

const showBackgroundNav = ref(false);
const isFooterVisible = ref(true);
const lastScrollPosition = ref(0);

const handleFooterScroll = () => {
  const currentScrollPosition = window.scrollY;

  if (currentScrollPosition > lastScrollPosition.value) {
    // Scrolling down
    isFooterVisible.value = false;
  } else {
    // Scrolling up
    isFooterVisible.value = true;
  }

  lastScrollPosition.value = currentScrollPosition;
}

const checkScroll = () => {
  if (window.scrollY > 130) {
    if (showBackgroundNav.value === false) {
      showBackgroundNav.value = true;
    }
  } else {
    if (showBackgroundNav.value === true) {
      showBackgroundNav.value = false;
    }
  }
};

const handleScroll = () => {
  checkScroll();
  handleFooterScroll();
};

// Handle window resize
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

const showNavBar = computed(() => {
  return !["Subscription", "Login", "SignUp"].includes(route.name as string);
});

const showFooter = computed(() => {
  return !["Subscription"].includes(route.name as string);
});

const showFooterMobile = computed(() => {
  return !["Subscription", "Home", "Login", "SignUp"].includes(route.name as string);
});
</script>

<template>
  <div :class="[showBackgroundNav, `${showBackgroundNav ? 'bg-opacity-100' : 'bg-opacity-0'} `]"
    class="transition-all duration-500 flex bg-black flex-col fixed left-0 right-0 bg-gradient-to-b from-black from-0% via-black/50 via-40% to-black/0 lg:justify-between justify-between items-center z-30"
    id="navbar-wrapper">
    <FlashSaleBanner />
    <NavMenu v-if="showNavBar" />
  </div>
  <div class="min-h-screen items-stretch flex-col" :class="`${showFlashSaleBanner ? 'mt-[80px]' : 'top-[0]'}`">
    <div class="relative">
      <SubHeader />
      <router-view></router-view>
    </div>

    <Footer v-if="showFooter" />
  </div>
  <MobileNavBar :class="isFooterVisible ? 'translate-y-0' : 'translate-y-full'"
    class="z-50 transition-transform duration-300" v-if="showFooterMobile" />
</template>

<style scoped lang="postcss">

</style>
