<script setup lang="ts">
import { CourtType, NftSkill, Scarcity, Skill } from "fungi-types";
import { useI18n } from "vue-i18n";
import L5Chart from "~/components/marketplace/L5Chart.vue";
import L10Chip from "~/components/marketplace/L10Chip.vue";
import BonusChip from "../marketplace/BonusChip.vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    l10: number;
    tokenId: number;
    skill: Skill;
    scarcity: Scarcity;
    court: CourtType;
    l5: NftSkill["l5"];
    season: string;
    xp?: number;
  }>(),
  {
    l10: undefined,
    l5: undefined,
  }
);
</script>

<template>
  <div class="w-full flex items-stretch justify-between text-black">
    <div class="flex flex-row gap-1">
      <L10Chip size="md" :score="l10" />
      <BonusChip :xp="xp" :season="season" :scarcity="scarcity" />
    </div>

    <L5Chart :l5="l5" />
  </div>
  <div class="flex flex-row gap-0.5 text-xs flex-wrap">
    <Tooltip :text="t('skill')">
      <div
        class="bg-neutral-950 rounded-md md:p-0.5 px-1 md:px-1.5 text-neutral-400 capitalize cursor-pointer"
      >
        {{ t(`skills.${skill}`) }}
      </div>
    </Tooltip>
    <Tooltip :text="t('scarcity')">
      <div
        class="bg-neutral-950 rounded-md md:p-0.5 px-1 md:px-1.5 text-neutral-400 capitalize cursor-pointer"
      >
        {{ t(`scarcities.${scarcity}`) }}
      </div>
    </Tooltip>
    <Tooltip :text="t('courtType')">
      <div
        class="bg-neutral-950 rounded-md md:p-0.5 px-1 md:px-1.5 text-neutral-400 capitalize cursor-pointer"
      >
        {{ t(`court.${court}`) }}
      </div>
    </Tooltip>
  </div>
</template>

<style scoped lang="scss">
.return-bg {
  @apply bg-skill-return;
}
.service-bg {
  @apply bg-skill-service;
}
.power-bg {
  @apply bg-skill-power;
}
.mental-bg {
  @apply bg-skill-mental;
}
</style>
