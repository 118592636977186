<script lang="ts" setup>
import { computed, onBeforeMount, Ref, ref } from "vue";
import { BigNumber } from "ethers";
import AuctionItem from "~/components/marketplace/AuctionItem.vue";
import useConvert from "~/composables/useConvert";
import router from "~/router";
import { NotifType, useStoreApp } from "~/stores/storeApp";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { displayError } from "~/utils/errors";
import { useI18n } from "vue-i18n";
import { maticMinAmount } from "~/application/config";
import Trans from "~/modules/translations";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { useStripeStore } from "~/composables/useStripe";
import Currency from "~/common/enums/Currency";
import { NftSkill } from "fungi-types";
import PillOptions from "~/components/common/PillOptions.vue";
import usePOLPrice from "~/stores/POLprice";
import FiatInputWithPolEquivalent from "~/components/common/FiatInputWithPolEquivalent.vue";
import { ArrowLeft } from "lucide-vue-next";
import { getCurrencySymbol } from "~/utils/currency";

const props = withDefaults(
  defineProps<{
    onboarding?: boolean;
  }>(),
  {
    onboarding: false,
  }
);

const { t } = useI18n();
const storeMarketplace = useStoreMarketplace();
const storeUser = useStoreUserV2();
const storeApp = useStoreApp();

const { weiToMatic } = useConvert;
const polPrice = usePOLPrice();
const currency = ref<Currency | "wmtc">(storeUser.preferredCurrency ?? Currency.USD);
const decimals = ref(3);
const lastBidAmount = ref(BigNumber.from(0));
const minAmount = ref(BigNumber.from(0));
const bidAmount = ref(BigNumber.from(0));
const fiatAmount = ref(0);
const fiatError = ref(false);

onBeforeMount(() => {
  lastBidAmount.value = storeMarketplace.selectedItem.lastBidAmount
    ? BigNumber.from(storeMarketplace.selectedItem.lastBidAmount)
    : BigNumber.from(storeMarketplace.selectedItem.startedAmount);
  minAmount.value = lastBidAmount.value.add(lastBidAmount.value.mul(10).div(100));
  bidAmount.value = lastBidAmount.value.add(lastBidAmount.value.mul(10).div(100));
  polPrice.refreshRates();
});

const amountTooLow = computed(() => bidAmount.value.lt(minAmount.value));
function updateBidAmount(e: BigNumber) {
  bidAmount.value = e;
}

async function login() {
  storeApp.setBidModalState(false);
  router.push(Trans.i18nRoute({ name: "Login" })).catch(console.error);
}

async function bid() {
  if (props.onboarding) {
    useOnboarding().goToNextStep();
    return;
  }

  const end = new Date(storeMarketplace.selectedItem.endedAt);
  // Check if the auction has ended
  if (end.getTime() <= new Date().getTime()) {
    storeApp.displayNotification(
      NotifType.BID_FAILED,
      storeMarketplace.selectedItem,
      t("errors.auction.timeover")
    );
    return storeApp.setBidModalState(false);
  }

  storeMarketplace.bidInProgress = true;

  try {
    const allow = await storeUser.allowWmtc(weiToMatic(bidAmount.value));
    if (!allow && storeUser.currentMaticBalance < maticMinAmount) {
      // Not enough matic to allow, redirect to my account
      storeApp.setBidModalState(false);
      storeMarketplace.bidInProgress = false;
      storeApp.showNeedMatic = true;
      return;
    }

    await storeMarketplace.bidOnCard(storeMarketplace.selectedItem.cuid, bidAmount.value);
    storeMarketplace.bidInProgress = false;
  } catch (error) {
    displayError(error);
    storeMarketplace.bidInProgress = false;
  }

  storeApp.setBidModalState(false);
}

const checkoutRef = ref<HTMLElement>();
const stripe = useStripeStore();

async function bidWithStripe() {
  if (props.onboarding) {
    useOnboarding().goToNextStep();
    return;
  }

  if (!checkoutRef.value || isNaN(fiatAmount.value) || fiatError.value) {
    return;
  }

  if (BigNumber.from(Math.ceil(fiatAmount.value * 100)).gt(Number.MAX_SAFE_INTEGER - 1)) {
    throw new Error("Bid amount too high");
  }

  storeMarketplace.bidInProgress = true;

  await stripe.launchCheckoutSession(
    checkoutRef as Ref<HTMLElement>,
    storeMarketplace.selectedItem.cuid,
    Math.ceil(fiatAmount.value * 100),
    currency.value as Currency,
    () => {
      storeMarketplace.bidInProgress = false;
      storeApp.setBidModalState(false);
    },
    () => {
      storeMarketplace.bidInProgress = false;
      polPrice.refreshRates();
    }
  );
}

function handleStripeModalBackButton() {
  stripe.cancelCheckoutSession();
  storeMarketplace.bidInProgress = false;
}

const cardTitle = computed(() => {
  const card = storeMarketplace.selectedItem.card;

  if (card.card_type === "nft_skills")
    return `${(card as NftSkill).firstName} ${(card as NftSkill).lastName}`;

  return card.title;
});
</script>

<template>
  <div class="grid grid-cols-3 text-white md:max-w-[60vw] relative">
    <div
      v-show="stripe.checkoutSessionInProgress"
      class="col-span-3 bg-[#fff] p-4 text-slate-950 rounded-md md:w-[500px]"
    >
      <h1 class="text-md md:text-lg font-semibold">
        {{ $t("modals.bid.title") }}
      </h1>
      <OvTicker
        :cuid="storeMarketplace.selectedItem.cuid"
        :end-date="storeMarketplace.selectedItem.endedAt"
        :status="storeMarketplace.selectedItem.status"
        class="self-end text-sm [&_span]:text-neutral-500 stroke-neutral-500"
      />
      <div class="stripe-checkout-container" ref="checkoutRef"></div>
      <div class="flex justify-end items-center pt-4">
        <button
          @click="handleStripeModalBackButton"
          class="text-slate-500 hover:font-semibold flex items-center gap-2"
        >
          <ArrowLeft />
          <span>
            {{ $t("modals.bid.back") }}
          </span>
        </button>
      </div>
    </div>

    <div
      class="hidden md:flex gap-f4 mx-auto bg-dark-blury gradient-border bg-left"
      v-show="!stripe.checkoutSessionInProgress"
    >
      <AuctionItem
        :background="false"
        :item="storeMarketplace.selectedItem"
        :is-interactive="false"
        :show-card-details="false"
      />
    </div>

    <div
      class="flex flex-col gap-f3 col-span-3 md:col-span-2 bg-dark-blury before:z-[-1] shadow-xl py-f4 px-f3 lg:py-f5 lg:px-f4 rounded-2xl md:rounded-l-none md:before:rounded-r-2xl md:gradient-border justify-around"
      v-show="!stripe.checkoutSessionInProgress"
    >
      <h1 class="text-md md:text-lg font-semibold">{{ $t("modals.bid.title") }}</h1>
      <h2 class="hidden md:block text-2xl font-display uppercase truncate">
        {{ cardTitle }}
      </h2>
      <div class="flex flex-col gap-f1 p-f3 md:hidden">
        <FlashSaleCard
          :item="storeMarketplace.selectedItem"
          :has-background="false"
          class="bg-none"
        />
        <OvTicker
          :cuid="storeMarketplace.selectedItem.cuid"
          :end-date="storeMarketplace.selectedItem.endedAt"
          :status="storeMarketplace.selectedItem.status"
          class="self-end text-sm"
        />
      </div>

      <PillOptions
        v-if="storeUser.preferredCurrency !== 'wmtc'"
        :options="[
          {
            key: storeUser.preferredCurrency ?? Currency.USD,
            label: getCurrencySymbol(storeUser.preferredCurrency ?? Currency.USD),
          },
          { key: 'wmtc', label: 'wPOL' },
        ]"
        v-model:selected="currency"
      />

      <div class="flex flex-col gap-f3">
        <span class="font-bold uppercase">{{ $t("modals.bid.total") }}</span>

        <ConverterInput
          :value="bidAmount"
          @wei-amount="updateBidAmount"
          :hideConversion="false"
          :min="minAmount"
          :decimals="decimals"
          :canSwitchMode="false"
          v-if="currency === 'wmtc'"
        >
          <template #msg v-if="amountTooLow">
            <span class="text-error">{{ $t("modals.bid.toolow") }}</span>
          </template>
        </ConverterInput>

        <FiatInputWithPolEquivalent
          v-else
          v-model="fiatAmount"
          v-model:error="fiatError"
          :min-pol-amount="minAmount"
          :currency="currency as Currency"
          :default-to-min="true"
        >
          <template #too-low>
            <span class="text-error">{{ $t("modals.bid.tooLow") }}</span>
          </template>
          <template #format-error>
            <span class="text-error">{{ $t("modals.bid.formatError") }}</span>
          </template>
        </FiatInputWithPolEquivalent>
      </div>

      <ButtonPrimary
        :blue="true"
        v-if="!storeUser.isWalletConnected"
        class="text-center"
        @click="login"
      >
        {{ $t("nav.login") }}
      </ButtonPrimary>

      <ButtonPrimary
        :blue="true"
        v-else
        :class="{ 'ring-2 animate-pulse': onboarding }"
        @click="() => (currency === 'wmtc' ? bid() : bidWithStripe())"
        :disabled="storeMarketplace.bidInProgress || amountTooLow"
      >
        <icon-fgc-loading v-if="storeMarketplace.bidInProgress" />
        <span v-else> {{ $t("modals.bid.confirm") }}</span>
      </ButtonPrimary>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-left {
  @apply py-f3 px-f1 rounded-l-2xl before:rounded-l-2xl before:z-[-1] shadow-xl;
}
</style>
