<script lang="ts" setup>
import { Toaster as Sonner, type ToasterProps } from "vue-sonner";

const props = defineProps<ToasterProps>();
</script>

<template>
  <Sonner
    class="toaster group"
    v-bind="props"
    :visible-toasts="5"
    :toast-options="{
      classes: {
        toast:
          'group toast group-[.toaster]:text-white group-[.toaster]:border-border group-[.toaster]:shadow-lg',
        description: 'group-[.toast]:text-white',
        actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-white',
        cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-white',
      },
    }"
    expanded
  />
</template>
