<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import { onMounted } from "vue";
import { useStoreUserV2 } from "~/stores/storeUserV2";

const { t } = useI18n();

const userStore = useStoreUserV2();
onMounted(() => {
  if (userStore.profileDrawerPage !== "default") {
    userStore.openProfileDrawer("default");
  }
});
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <div class="hidden md:block h-[33vh] w-full score-card-spacer">
      <!-- Spacer div to shift modal a bit below the center of the screen (where applied) -->
    </div>

    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <p class="whitespace-pre-wrap">
        {{ t("onboarding.step17.youreReady") }}
      </p>

      <div class="p-2">
        <PaginatedOnboardingFooter continue-text-key="onboarding.step17.letsGo" />
      </div>
    </div>
  </div>
</template>
