<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { formatDate } from "~/utils/dates";
import { useCardDetails } from "~/components/card-details/use-card-details";
import useDynamicSort from "~/composables/useDynamicSort";
import SortIndicator from "~/components/common/SortIndicator.vue";

const { t, locale } = useI18n();

const cardDetails = useCardDetails();
const { ownersHistory: ownersHistoryRef } = storeToRefs(cardDetails);

const ownersHistory = useDynamicSort(ownersHistoryRef);
</script>

<template>
  <table class="table-auto border-separate border-spacing-0">
    <thead class="bg-black align-middle [&_th]:font-normal">
      <tr>
        <th
          class="text-start py-3 pl-4 rounded-tl-lg border border-r-0 border-grey-30 border-opacity-20 align-middle"
        >
          <div class="flex flex-row items-center gap-2">
            <span>
              {{ t("cardDetails.cardPreviousOwners.table.name") }}
            </span>
            <SortIndicator
              :value="ownersHistory.filters.value.owner"
              @click="ownersHistory.changeFilter('owner')"
            />
          </div>
        </th>
        <th class="text-start border-t border-b border-grey-30 border-opacity-20">
          <div class="flex flex-row justify-end items-center gap-2 px-4">
            <span>
              {{ t("cardDetails.cardPreviousOwners.table.boughtFor") }}
            </span>
            <SortIndicator
              :value="ownersHistory.filters.value.price"
              @click="ownersHistory.changeFilter('price')"
            />
          </div>
        </th>
        <th
          class="text-start pr-4 rounded-tr-lg border border-l-0 border-grey-30 border-opacity-20"
        >
          <div class="flex flex-row justify-center items-center gap-2">
            <span>
              {{ t("cardDetails.cardPreviousOwners.table.date") }}
            </span>
            <SortIndicator
              :value="ownersHistory.filters.value.acquiredAt"
              @click="ownersHistory.changeFilter('acquiredAt')"
            />
          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-if="cardDetails.ownersHistory.length === 0">
        <td
          class="py-3 pr-4 align-middle border border-t-0 border-l-0 border-grey-30 border-opacity-20"
          colspan="7"
        >
          <div class="flex flex-row items-center justify-center text-sm">
            {{ t("cardDetails.cardPreviousOwners.noPreviousOwners", "No previous owners") }}
          </div>
        </td>
      </tr>
      <tr v-for="previousOwner in ownersHistory.sorted.value" :key="previousOwner.transaction">
        <td
          class="py-3 pl-4 align-middle border border-t-0 border-r-0 border-grey-30 border-opacity-20"
        >
          {{ previousOwner.owner }}
        </td>
        <td class="py-3 align-middle border-b border-grey-30 border-opacity-20">
          <div class="flex justify-end items-center gap-2 px-4">
            <span>
              {{ previousOwner.price.toFixed(2) }}
            </span>
            <IconFgcWMatic class="w-4" />
          </div>
        </td>
        <td
          class="py-3 pr-4 align-middle border border-t-0 border-l-0 border-grey-30 border-opacity-20"
        >
          <div class="flex justify-center items-center">
            {{
              formatDate(
                previousOwner.acquiredAt,
                locale,
                locale === "fr" ? "dd/MM/yyyy" : "MM/dd/yyyy"
              )
            }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
