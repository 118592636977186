<script setup lang="ts">
import { ref } from "vue";
import { useClipboard } from "@vueuse/core";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { Link } from "lucide-vue-next";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const userStore = useStoreUserV2();

const { copy } = useClipboard();

const copyTimeout = ref<NodeJS.Timeout | undefined>(undefined);

function copyText() {
  copy(userStore.walletAddress);

  clearTimeout(copyTimeout.value);
  copyTimeout.value = setTimeout(() => {
    copyTimeout.value = undefined;
  }, 2500);
}
</script>

<template>
  <form
    @submit.stop.prevent
    class="flex items-center gap-2 w-full bg-slate-900 rounded-lg pl-3 pr-2 py-2"
  >
    <span class="truncate flex-1 text-slate-600 text-[10px]">
      {{ userStore.walletAddress }}
    </span>

    <button
      @click.stop.prevent="copyText"
      class="flex flex-row items-center gap-2 bg-slate-950 text-slate-300 px-2.5 py-1 text-xs rounded-full border border-slate-600 hover:bg-slate-600 hover:text-white transition-colors"
    >
      <Link class="h-[1em] w-[1em]" />
      <span>
        {{ copyTimeout ? t("copied", "Copied") : t("copy", "Copy") }}
      </span>
    </button>
  </form>
</template>
