<script lang="ts" setup>
import { BigNumber } from "ethers";
import { computed, ref } from "vue";
import AuctionItem from "~/components/marketplace/AuctionItem.vue";
import useConvert from "~/composables/useConvert";
import router from "~/router";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { displayError } from "~/utils/errors";
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";
import { storeToRefs } from "pinia";
import { useSecondaryMarketplace } from "~/stores/storeSecondaryMarketplace";
import { AuctionStatus } from "~/common/enums/auction";

const { t } = useI18n();
const storeMarketplace = useStoreMarketplace();
const secondaryMarketplace = useSecondaryMarketplace();
const { selectedItem } = storeToRefs(storeMarketplace);
const storeUser = useStoreUserV2();
const storeApp = useStoreApp();

const { weiToMatic, weiToUsd, formatUsd } = useConvert;

const amount = computed(() => {
  return {
    matic: weiToMatic(BigNumber.from(selectedItem.value.startedAmount)),
    usd: formatUsd(weiToUsd(BigNumber.from(selectedItem.value.startedAmount))),
  };
});
const loading = ref(false);

function login() {
  storeApp.setBidModalState(false);
  router.push(Trans.i18nRoute({ name: "Login" }));
}

async function buy() {
  loading.value = true;

  try {
    await secondaryMarketplace.buyItem(selectedItem.value);
  } catch (e: any) {
    displayError(e.message);
  }

  loading.value = false;
}
</script>

<template>
  <div class="grid grid-cols-3 text-white md:max-w-[60vw]">
    <div class="hidden md:flex gap-f4 mx-auto bg-dark-blury gradient-border bg-left">
      <AuctionItem :background="false" :item="selectedItem" :isInteractive="false" />
    </div>
    <div
      class="flex flex-col gap-f3 col-span-3 md:col-span-2 bg-dark-blury before:z-[-1] shadow-xl py-f4 px-f3 lg:py-f5 lg:px-f4 rounded-2xl md:rounded-l-none md:before:rounded-r-2xl md:gradient-border justify-around"
    >
      <h1 class="title-sm md:title-md font-display uppercase">{{ $t("modals.buy.title") }}</h1>
      <div class="flex flex-col gap-f1 p-f3 md:hidden">
        <FlashSaleCard :item="selectedItem" :has-background="false" class="bg-none" />
        <OvTicker
          :cuid="selectedItem.cuid"
          :end-date="selectedItem.endedAt"
          :status="selectedItem.status"
          class="self-end text-sm"
        />
      </div>
      <div class="flex flex-col gap-f3">
        <span class="font-bold uppercase">{{ $t("modals.buy.asked-price") }}</span>
        <div
          class="flex cursor-not-allowed grayscale items-center gap-f2 px-3 py-2 bg-white/20 text-white filter border border-none text-lg rounded-md"
        >
          <icon-fgc-w-matic width="20" height="20" />
          <span>{{ amount.matic }}</span>
        </div>
        <span class="text-grey">≈ {{ amount.usd }}</span>
      </div>
      <ButtonPrimary
        :blue="true"
        v-if="!storeUser.isWalletConnected"
        class="text-center"
        @click="login"
      >
        {{ $t("nav.login") }}
      </ButtonPrimary>
      <ButtonPrimary
        v-else
        :blue="true"
        @click="buy"
        :disabled="selectedItem.status === AuctionStatus.ENDED"
      >
        <span v-if="selectedItem.status === AuctionStatus.ENDED">
          {{ $t("pages.marketplace.trade.sold") }}</span
        >
        <icon-fgc-loading v-else-if="loading" />
        <span v-else> {{ $t("modals.buy.confirm") }}</span>
      </ButtonPrimary>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-left {
  @apply py-f3 px-f1 rounded-l-2xl before:rounded-l-2xl before:z-[-1] shadow-xl;
}
</style>
