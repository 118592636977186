import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { notificationApi } from "~/api/notification";
import { MercureService } from "~/services/mercureService";
import { InAppNotificationEventPayload, MercureEventType } from "fungi-types";
import { useStoreUserV2 } from "~/stores/storeUserV2";

enum NotificationStatus {
  READ = "READ",
  SENT = "SENT",
  CREATED = "CREATED",
  FAILURE = "FAILURE",
}

export type Notification = {
  id: string;
  title: string;
  description: string;
  image: string | null;
  link: string | null;
  buttonText: string | null;
  createdAt: Date;
  status: NotificationStatus;
  publicationDate: Date;
};

const lineBreakRegex = /\n/g;

export const useNotificationCenter = defineStore("notification-center", () => {
  const storeUser = useStoreUserV2();

  const open = ref(false);
  const hideReadNotifications = ref(false);

  const notifications = ref<Notification[]>([]);
  const tempNotifications = ref<Notification[]>([]);

  const allNotifications = computed(() => {
    return [...notifications.value, ...tempNotifications.value].sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    );
  });

  const unreadNotificationsCount = computed(() => {
    return allNotifications.value.filter((n) => n.status !== NotificationStatus.READ).length;
  });

  MercureService.getInstance().emitter.on(
    MercureEventType.IN_APP_NOTIFICATION,
    (event: InAppNotificationEventPayload) => {
      notifications.value.push({
        id: event.id,
        title: event.titleFr,
        description: event.descriptionFr,
        image: event.imageFr,
        link: event.linkFr,
        buttonText: event.buttonTextFr,
        createdAt: new Date(event.createdAt),
        publicationDate: new Date(event.publicationDate),
        status: NotificationStatus.CREATED,
      });
    }
  );

  MercureService.getInstance().emitter.on(MercureEventType.REFRESH_NOTIFICATIONS, () => {
    console.info(`Refreshing notifications`);
    fetchNotifications().catch(console.error);
  });

  setInterval(() => {
    if (!storeUser.isWalletConnected) return;

    fetchNotifications().catch(console.error);
  }, 5 * 60_000);

  async function fetchNotifications() {
    notifications.value = (
      await notificationApi.inApp.list.query({
        showRead: !hideReadNotifications.value,
      })
    )
      .map((n) => ({
        ...n,
        description: n.description.replace(lineBreakRegex, "<br />"),
        publicationDate: new Date(n.publicationDate),
        createdAt: new Date(n.publicationDate ?? n.createdAt),
      }))
      .filter((n) => {
        return n.publicationDate.getTime() < Date.now();
      });
  }

  async function markAllAsRead() {
    await notificationApi.inApp.markAllAsRead.mutate();

    notifications.value = notifications.value.map((n) => ({
      ...n,
      status: NotificationStatus.READ,
    }));
    tempNotifications.value = tempNotifications.value.map((n) => ({
      ...n,
      status: NotificationStatus.READ,
    }));
  }

  async function markAsRead(id: string) {
    if (notifications.value.some((n) => n.id === id)) {
      await notificationApi.inApp.updateStatus.mutate({
        id,
        status: NotificationStatus.READ,
      });

      notifications.value = notifications.value.map((n) => ({
        ...n,
        status: n.id === id ? NotificationStatus.READ : n.status,
      }));

      console.log(notifications.value);
    } else {
      tempNotifications.value = tempNotifications.value.map((n) => ({
        ...n,
        status: n.id === id ? NotificationStatus.READ : n.status,
      }));
    }
  }

  return {
    open,
    notifications,
    tempNotifications,
    allNotifications,
    hideReadNotifications,
    unreadNotificationsCount,
    fetchNotifications,
    markAllAsRead,
    markAsRead,
  };
});
