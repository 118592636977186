import { ProviderType } from "@/application/enums/ProviderType.enum";
import { ContractService } from "@/services/contractService";
import { BigNumber, ethers, type ContractTransaction } from "ethers";
import type { WmaticContract } from "@/application/contracts/WmaticContract";
import { computed, ref, shallowReactive, type ShallowReactive } from "vue";
import { defineStore } from "pinia";
import {
  wmaticContractMainnet,
  wmaticContractMumbai,
  isProd,
  seaportContractMainnet,
  seaportContractMumbai,
  gasStation,
} from "@/application/config";
import { displayError } from "@/utils/errors";
import wmaticAbi from "@/application/contracts/wmatic.json";
import { toEther } from "@/composables/web3Utils";

export const useStoreContract = defineStore("contract-store", () => {
  const seaportContract = isProd ? seaportContractMainnet : seaportContractMumbai;
  const contractWmtcAddress = isProd ? wmaticContractMainnet : wmaticContractMumbai;

  const contractWmtc = ref<ShallowReactive<ContractService<WmaticContract>>>();

  const initialized = ref<boolean>(false);
  const isConnected = ref<boolean>(false);
  const lastTransactionHash = ref<string>("");

  const providerType = computed(() => contractWmtc.value?.providerType || ProviderType.NONE);

  /**
   * Init Provider connection and instantiate Contract
   * @returns
   */
  async function init(userProvider?: any) {
    (contractWmtc.value = shallowReactive(
      new ContractService<WmaticContract>(
        contractWmtcAddress,
        wmaticAbi as unknown as ethers.ContractInterface
      )
    )),
      (isConnected.value = false);
    try {
      contractWmtc.value!.userProvider = userProvider;
      await contractWmtc.value!.init();
      initialized.value = true;
      isConnected.value = true;
      return Promise.resolve(true);
    } catch (err) {
      displayError(err);
      return Promise.reject(err);
    }
  }

  async function connectUserProvider(userProvider: any) {
    isConnected.value = false;
    try {
      if (initialized.value === false) {
        await init(userProvider);
        isConnected.value = true;
      } else {
        await contractWmtc.value!.connectUserProvider(userProvider);
        isConnected.value = true;
      }
    } catch (err) {
      displayError(err);
    }
  }

  async function connectRpcProvider() {
    isConnected.value = false;
    try {
      await contractWmtc.value!.connectRpcProvider();

      isConnected.value = true;
    } catch (err) {
      displayError(err);
    }
  }

  async function getWmtcBalance(address: string): Promise<number> {
    let value = 0;
    try {
      const res = await contractWmtc.value!.contract.balanceOf(address);
      value = toEther(res);
    } catch (err) {
      displayError(err);
      Promise.reject(err);
    }
    return Promise.resolve(value);
  }

  async function getAllowedWmtc(address: string, contract?: string): Promise<number> {
    try {
      const res = BigNumber.from(
        await contractWmtc.value!.contract.allowance(address, contract ?? seaportContract)
      );
      return Promise.resolve(toEther(res));
    } catch (err) {
      displayError(err);
      Promise.reject(err);
    }
    return Promise.resolve(0);
  }

  async function allowWmtc(amount: number = 1000, contract?: string): Promise<ContractTransaction> {
    lastTransactionHash.value = "";
    const value = amount.toString();
    try {
      const transaction: ContractTransaction = await contractWmtc.value!.contract.approve(
        contract ?? seaportContract,
        ethers.utils.parseEther(value)
      );
      await transaction.wait(1);
      return Promise.resolve(transaction);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getGasFees(
    amount: string
  ): Promise<{ maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber }> {
    const urlReq = new URL(gasStation);
    const query = await fetch(urlReq);
    const polyFees = await query.json();

    const maxFeePerGas = ethers.utils.parseUnits(polyFees.fast.maxFee.toFixed(9), "gwei");
    const maxPriorityFeePerGas = ethers.utils.parseUnits(
      polyFees.fast.maxPriorityFee.toFixed(9),
      "gwei"
    );
    const transactionProperties = {
      maxFeePerGas: maxFeePerGas,
      maxPriorityFeePerGas: maxPriorityFeePerGas,
    };
    return Promise.resolve(transactionProperties);
  }

  async function convertToWmtc(amount: string): Promise<ContractTransaction> {
    lastTransactionHash.value = "";
    const value = ethers.utils.parseEther(amount);
    try {
      const transaction: ContractTransaction = await contractWmtc.value!.contract.deposit({
        value: value,
      });
      lastTransactionHash.value = transaction.hash;
      await transaction.wait(1);
      return Promise.resolve(transaction);
    } catch (err) {
      displayError(err);
      return Promise.reject(err);
    }
  }

  async function convertToMatic(amount: string): Promise<ContractTransaction> {
    lastTransactionHash.value = "";
    const value = ethers.utils.parseEther(amount);
    try {
      const transaction: ContractTransaction = await contractWmtc.value!.contract.withdraw(value);
      lastTransactionHash.value = transaction.hash;
      await transaction.wait(1);
      return Promise.resolve(transaction);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    init,
    connectUserProvider,
    connectRpcProvider,
    getAllowedWmtc,
    allowWmtc,
    convertToWmtc,
    convertToMatic,
    getWmtcBalance,
    getGasFees,
    contractWmtcAddress,
    initialized,
    isConnected,
    lastTransactionHash,
    contractWmtc,
    providerType,
  };
});
