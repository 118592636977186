<script setup lang="ts"></script>

<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 0C16.023 0 20.5 4.477 20.5 10C20.5021 12.6255 19.4715 15.1464 17.6309 17.0186C15.7902 18.8908 13.2872 19.9641 10.662 20.0066C8.03689 20.0491 5.50034 19.0576 3.6 17.246C1.69966 15.4344 0.587989 12.9482 0.505 10.324L0.5 10L0.504 9.72C0.652 4.327 5.07 0 10.5 0ZM10.5 9H9.5L9.383 9.007C9.13995 9.03591 8.91594 9.15296 8.75341 9.33597C8.59088 9.51897 8.50111 9.75524 8.50111 10C8.50111 10.2448 8.59088 10.481 8.75341 10.664C8.91594 10.847 9.13995 10.9641 9.383 10.993L9.5 11V14L9.507 14.117C9.53332 14.3402 9.63408 14.5481 9.79301 14.707C9.95194 14.8659 10.1598 14.9667 10.383 14.993L10.5 15H11.5L11.617 14.993C11.8402 14.9667 12.0481 14.8659 12.207 14.707C12.3659 14.5481 12.4667 14.3402 12.493 14.117L12.5 14L12.493 13.883C12.4691 13.679 12.3829 13.4873 12.2462 13.3339C12.1095 13.1806 11.9289 13.0731 11.729 13.026L11.617 13.006L11.5 13V10L11.493 9.883C11.4667 9.65978 11.3659 9.45194 11.207 9.29301C11.0481 9.13408 10.8402 9.03332 10.617 9.007L10.5 9ZM10.51 6L10.383 6.007C10.14 6.03591 9.91594 6.15296 9.75341 6.33597C9.59088 6.51897 9.50111 6.75524 9.50111 7C9.50111 7.24476 9.59088 7.48103 9.75341 7.66403C9.91594 7.84704 10.14 7.96409 10.383 7.993L10.5 8L10.627 7.993C10.87 7.96409 11.0941 7.84704 11.2566 7.66403C11.4191 7.48103 11.5089 7.24476 11.5089 7C11.5089 6.75524 11.4191 6.51897 11.2566 6.33597C11.0941 6.15296 10.87 6.03591 10.627 6.007L10.51 6Z"
      fill="#0095FF"
    />
  </svg>
</template>
