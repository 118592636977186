<script setup lang="ts">
import { ChevronRight } from "lucide-vue-next";

const props = defineProps<{
  nav?: boolean;
  disabled?: boolean;
}>();

const emits = defineEmits(["click"]);
</script>

<template>
  <button
    :disabled="props.disabled"
    @click.stop="() => !props.disabled && emits('click')"
    class="flex flex-row items-center justify-start gap-3 py-3 px-5 bg-slate-900 hover:bg-slate-800 disabled:opacity-15 text-white rounded-lg"
    tabindex="-1"
  >
    <span v-if="$slots.icon">
      <slot name="icon"></slot>
    </span>

    <span class="grow text-start text-sm max-w-full overflow-hidden text-ellipsis">
      <slot />
    </span>

    <ChevronRight v-if="props.nav" />
  </button>
</template>
